<template>

  <div id="header" v-if="!isAuth">
    <div class="container d-flex justify-content-between align-items-center">
      <AppLogo @click="$router.push('/')" ></AppLogo>

      <button class="header__button" @click="$router.push('/register')">Зарегистрироваться</button>
    </div>
  </div>
  <div v-if="isAnyLoading || !isPageLoaded" class="block__loader">
    <loader></loader>
  </div>
  <div v-else  id="user" class="container my-4">
    <div class="user__title">
      <div class="mr-3 user__img">
        <img alt="user" src="@/assets/user.png">
      </div>
      <div class="user__name d-flex  flex-row">
        <div class="d-flex flex-column justify-content-center">
          <h3>{{ user.firstName }} {{ user.lastName }}</h3>
          <h4 class="text-uppercase"> {{ user.position }}</h4>
        </div>

      </div>
    </div>

    <transition name="fade">

      <UserTalentTest ref="talent-result" :email="user.email" :is-share-result="!isAuth"
                      :hide-talent="(user.hideTalent && this.$route.params.id !== '')" :is-email-verified="true"
                      :isTestDone="this.isTestDone" :majorType="this.majorType" :minorTypes="this.minorTypes"
                      :result="user.talentResult" :show-star="isPageLoaded" :typesInfo="this.typesInfo" :typesName="this.typesName">
      </UserTalentTest>


    </transition>
  </div>

</template>

<script>
import Loader from "@/components/UI/Loader.vue";
import {mapGetters} from "vuex";
import api from "@/api";
import UserTalentTest from "@/components/User/UserTalentTest.vue";
import AppLogo from "@/components/UI/AppLogo.vue";

export default {
  components: {AppLogo, UserTalentTest, Loader},
  methods: {
    getUserShareResult(token) {
      api.getUserShareResult(token)
          .then(r =>{
            this.user = r.data;
            this.isPageLoaded = true;
            this.getDisplayingTypes(this.user.talentResult);
            this.typesName = Object.keys(this.typesInfo);
          })
    },
    getDisplayingTypes(results) {
      let keysSorted = Object.keys(results).sort(function (a, b) {
        return results[b] - results[a];
      })
      this.majorType = keysSorted[0];
      this.minorTypes[0] = keysSorted[1];
      this.minorTypes[1] = keysSorted[2];
    },
    getResultGenius() {
      const currentLocale = this.$i18n.locale;
      //const currentLocale = 'en';
      this.typesInfo = require(`@/i18n/locales/${currentLocale}/result_genius.json`);
      if (currentLocale == 'en') {
        this.isLocaleEn = true;
      }
    },
  },
  computed: {

    ...mapGetters({
      isAnyLoading: 'isAnyLoading',
      isAuth: "AuthModule/isAuthenticated",
    }),
  },
  data() {
    return {
      isPageLoaded: false,
      user: {},
      majorType: '',
      minorTypes: [],
      isTestDone: true,
      typesInfo: {},
      typesName: [],
    }
  },
  mounted() {
    this.getUserShareResult(this.$route.params.token)
  },
  created() {
    this.getResultGenius();
  },
}
</script>

<style scoped lang="scss">
.block__loader {
  width: 100%;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#header {
  position: sticky;
  top: 0px;
  z-index: 999;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 30px;
  color: #FFFFFF;
  background-color: #0A0F3F;
}

.user {

  &__title {
    display: flex;
    margin-bottom: 40px;

    img {
      border-radius: 22px;
      margin-right: 15px;
      object-fit: contain;
      max-width: 120px;
      max-height: 90px;
    }
  }

  &__name {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    background-color: #fff;
    border-radius: 22px;
    font-weight: 900;
    color: #1E1E1E;

    h3 {
      font-size: 15px;
    }

    h4 {
      font-size: 10px;
    }
  }

  &__setting {
    display: flex;
    align-items: center;

    & img {
      cursor: pointer;
      transition: all 0.3s linear;

      &:hover {
        transform: rotate(90deg) scale(1.1);
      }
    }

  }

  &__menu {
    display: flex;
    font-size: 13px;

    &__item {
      color: rgba(28, 29, 34, 0.3);
      border-bottom: 2px solid rgba(28, 29, 34, 0.3);
      cursor: pointer;
      transition: all 0.2s ease-in;

      &:hover {
        color: rgba(28, 29, 34, 0.6);
        border-bottom: 2px solid rgba(28, 29, 34, 0.6);
      }

      &.activeTest {
        color: #1C1D22;
        border-bottom: 2px solid #1C1D22;
      }

      &.hideTest {
        display: none;
      }
    }
  }
}

.header__button {
  font-size: 15px;
  padding: 8px 15px;
  background-color: #fff;
  color: #090D39;
  font-weight: 700;
  line-height: 21px;

  border-radius: 8px;
  border: none;
  transition: all .2s linear;

  &:hover {
    transform: scale(1.02);
  }
}

@media (min-width: 576px) {
  .user__name {
    h3 {
      font-size: 20px;
    }

    h4 {
      font-size: 13px;
    }
  }

  .verify__button {
    &.verify {
      margin-right: 40px;
    }
  }
}

@media (min-width: 768px) {

  .user__name {
    padding: 10px 25px;

    h3 {
      font-size: 22px;
    }

    h4 {
      font-size: 15px;
    }
  }

  .user__menu {
    font-size: 16px;
  }
}

@media (min-width: 992px) {}

@media (min-width: 1200px) {}

@media (min-width: 1400px) {
  #user {
    padding: 0 40px;
  }

  #header {
    height: 80px;
  }
}
</style>