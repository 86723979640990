import {createStore} from 'vuex';
import api from "@/api";
import {AuthModule} from "@/store/modules/AuthModule";


//TODO : Разбить на модули

const store = createStore({
    state() {
        return {
            result: {
                winter: 0,
                autumn: 0,
                spring: 0,
                summer: 0
            },
            questions: JSON.parse(sessionStorage.getItem('questions')) || [],
            anyLoading: false
        }

    },
    getters: {
        isAnyLoading(state){
            return state.anyLoading;
        }

    },
    mutations: {

        setLoadingFalse(state){
            state.anyLoading = false;
        }
    },
    actions: {
        finishLoad({commit}){
            commit('setLoadingFalse')
        },
    },
    modules: {
        AuthModule
    }
});
export default store

