<template>

  <OffCanvasMenu  :is-team-page="isTeamPage" :is-have-opened-join-requests="isHaveOpenedJoinRequests"
                :is-menu-opened="isMenuOpened" :is-requests-page="isRequestsPage" :is-user-page="isUserPage"/>

  <Header :is-user-page="isUserPage" :is-team-page="isTeamPage" :is-have-opened-join-requests="isHaveOpenedJoinRequests" :is-requests-page="isRequestsPage"></Header>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Header from "@/components/UI/Header.vue";
import OffCanvasMenu from "@/components/UI/OffCanvasMenu.vue";

export default {
  inheritAttrs:false,
  components: {OffCanvasMenu, Header},
  data: () => ({
    isMenuOpened: false,
  }),
  mounted() {
    this.$store.dispatch('AuthModule/updateSelfJoinRequests');
  },

  computed: {
    ...mapGetters({
      isAuth: "AuthModule/isAuthenticated",
      joinRequests: "AuthModule/getJoinRequests"
    }),
    isHaveOpenedJoinRequests() {
      for (var jr in this.joinRequests) {
        if (this.joinRequests[jr].status === 'OPENED') return true
      }
      return false
    },
    isUserPage(){
      return this.$route.path ==='/users' && !this.$route.params.id || this.$route.path.includes('/users/settings');
    },

    isRequestsPage() {
      return this.$route.path === '/requests'
    },
    isTeamPage() {
      return this.$route.path.includes('/teams') ||  this.$route.path.includes('/comp') || this.$route.path.includes('/users/') && !this.$route.path.includes('/users/settings')
    },
  },
  methods: {


    ...mapActions({
      logoutUser: "AuthModule/onLogout"
    })
  },

}
</script>

<style lang="scss" scoped>

hr {
  margin: 0 20px;
}


// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {

}
</style>
