<template>
  <div v-if="isLoading" class="block__loader">
	  <loader></loader>
  </div>
	<div v-else id="test" class="container m-2 m-xxl-3" :class="{'page-blocked': isPageBlocked}">
		<button class="button_info" @click="this.isShowModal = !this.isShowModal">
			<img src="@/assets/info.svg" class="mr-1" alt="info">
			{{ $t("genius_profile.btn_info") }}
		</button>
		<div class="test">
			<div :class="{ 'shake': isNeedGiveAnswer }" class="test__content">
				<div class="test__text-content">
					<div class="test__question">
						{{ this.questions[this.currentQuestion]["question"] }}
					</div>
					<div v-show="isNeedGiveAnswer" class="alert alert-danger" role="alert">
						{{ $t("genius_profile.not_missed") }}
					</div>
					<div class="test__answers">
						<div v-for="answer in mixedResponses">
							<test-answer :class="{ 'active': checkAnswer(answer) }"
								:question="this.questions[this.currentQuestion].answers[answer]"
								@click="changeAnswer(answer)"></test-answer>
						</div>
					</div>
				</div>
				<Transition name="slide-fade">
					<img :src="imageUrl" alt="" v-show="showImage" class="test__img">
				</Transition>
				<!-- <loader v-show="isLoadingImg"></loader> -->
			</div>

			<div class="test__control">
				<button id="back" class="control__button" @:click="prevQuestion">
					<img alt="" class="control__arrow back" src="@/assets/back.svg">
					{{ $t("genius_profile.btn_previous") }}
				</button>
				<test-progress-bar :class="'d-lg-flex'" :current-question="currentQuestion" :questions-length="questions.length">
				</test-progress-bar>
				<button v-show="(currentQuestion < questions.length - 1)" id="next" class="control__button" @click="nextQuestion">
					{{ $t("genius_profile.btn_next") }}
					<img alt="" class="control__arrow next" src="@/assets/next.svg">
				</button>
				<button v-show="(currentQuestion === questions.length - 1) && !checkAnswer('')" id="next"
					class="control__button finish" @click="sendResults" :disable="isSended">{{ $t("genius_profile.btn_finish") }}
				</button>
			</div>
		</div>
	</div>

	<transition name="modal">

		<ModalInfo v-if="isShowModal" :isTransparentBackground="true" @close="isShowModal = false">
      <div class="plug">
        <div class="row">
          <div class="col-12 col-lg-6 p-0">
            <div class="plug__text my-auto mx-auto h-100">
              <div class="plug__title">{{ $t("default_information.about_test") }}</div>
              <div>
                <p class="mb-3" v-html="$t('default_information.main') ">
                </p>
              </div>

            </div>
          </div>

          <div class="col-12 col-lg-6 d-flex flex-column justify-content-between">
            <div>
              <div class="plug__warn w-100 bg-white">
                <img src="@/assets/user/visible.svg" alt="icon">
                <div v-html="$t('default_information.info_visibility_result')"></div>
              </div>
              <div class="plug__warn w-100 bg-white">
                <img src="@/assets/user/clock.svg" alt="icon">
                <div v-html="$t('default_information.info_duration')"></div>
              </div>
              <div class="plug__warn w-100 bg-white">
                <img src="@/assets/user/info_black.svg" alt="icon">
                <div>
                  <p class="mb-2" v-html="$t('default_information.add_infotmation')"></p>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-center">
              <button class="plug__button mt-3 mt-lg-0"
                      @click="this.isShowModal = !this.isShowModal">{{$t("popup_info.btn_start") }}</button>
            </div>
          </div>
        </div>
      </div>

<!--			<div class="modal__title bg-white w-100">-->
<!--				<h3 class="p-0 m-0"> {{ $t('popup_info.title') }}</h3>-->
<!--			</div>-->
<!--			<div class="modal__subtitle bg-white w-100">-->
<!--				<p v-html="$t('popup_info.description')"></p>-->
<!--			</div>-->
<!--			<div class="d-flex justify-content-center">-->
<!--				<button class="modal__button text-center" @click="this.isShowModal = !this.isShowModal">{{-->
<!--					$t("popup_info.btn_start") }}</button>-->
<!--			</div>-->
		</ModalInfo>
	</transition>
</template>

<script>

import api, { backendUrl } from "@/api";
import TestAnswer from "@/components/Test/TestAnswer";
import TestProgressBar from "@/components/Test/TestProgressBar";
import Loader from "@/components/UI/Loader.vue"
import { mapActions, mapGetters } from "vuex";
import Modal from '@/components/UI/Modal/Modal.vue';
import ModalInfo from '@/components/UI/Modal/ModalInfo.vue'
//TODO: Вынести все URL
const testImagesUrl = '/resources/images/test/';
export default {
	name: "Test",
	components: { TestProgressBar, TestAnswer, Loader, Modal, ModalInfo},


	created() {
		this.getQuestions();
	},
	computed: {

		...mapGetters(
			{
				getUsername: 'AuthModule/getUsername',
			}
		),
		imageUrl() {
			let addres = backendUrl + testImagesUrl + this.currentQuestion + '.jpg';

			return addres;
		},
		mixedResponses() {
			let arr = [];
			for (let resultsKey in this.questions[this.currentQuestion].answers) {
				arr.push(resultsKey);
			}
			return arr.sort(() => Math.random() - 0.5)
		}
	},

	data() {
		return {
			isLoading: true,
			showImage: true,
			currentQuestion: 0,
			questions: [],
			"isNeedGiveAnswer": false,
			results: {},
			images: [],
			isShowModal: !this.$route.query.action,
			isPageBlocked: false,
		}
	},


	//TODO: Подумать над store
	beforeMount() {
		api.getTests().then(r => {
			//this.questions = r.data;
			this.isLoading = false;
			this.initResultsObject();
			for (let i in this.questions) {
				let addres = backendUrl + testImagesUrl + i + '.jpg';
				this.images.push(addres);
			}
			this.cacheImages();
      this.clearAnswer();
		});

	},


	methods: {
		getQuestions() {
			const currentLocale = this.$i18n.locale;
			this.questions = require(`@/i18n/locales/${currentLocale}/test_genius.json`);
		},

    clearAnswer() {
      for (let elem of this.questions) {
        elem["answer"] = "";
      }
    },

		cacheImages() {

			var imageCache = new function () {
				var me = this;

				var cache = [];
				var root = document.location.href.split("/");

				root.pop();
				root = root.join("/") + "/";

				me.push = function (src, loadEvent) {

					var item = new Image();

					if (cache[src] && loadEvent) {
						loadEvent(src);
					} else {
						if (loadEvent) {
							item.onload = loadEvent;
							item.onerror = loadEvent;
						}
						cache[src] = item;
					}

					item.src = src;
				};

				me.pushArray = function (array, imageLoadEvent, imagesLoadEvent) {
					var numLoaded = 0;
					var arrayLength = array.length;
					for (var i = 0; i < arrayLength; i++) {
						me.push(array[i], function (e) {
							if (imageLoadEvent) {
								imageLoadEvent(e);
							}
							numLoaded++;
							if (numLoaded == arrayLength) {
								setTimeout(function () {
									imagesLoadEvent(e);
								}, 1);
							}
						});
					}
				};
			}();
			imageCache.pushArray(this.images, () => { }, () => { });
		},

		...mapActions({ sendResult: 'AuthModule/onSendTalentResult' }),

		download(content, fileName, contentType) {
			var a = document.createElement("a");
			var file = new Blob([content], { type: contentType });
			a.href = URL.createObjectURL(file);
			a.download = fileName;
			a.click();
		},
		sendStatistics() {
			let stats = [];
			for (let i in this.questions) {
				stats.push({
					"answer": this.questions[i].answer,
					"number": this.questions[i].number
				})
			}
			api.sendTalentStatistics(stats)
		},
		sendResults() {
			this.isLoading = true;
			this.isPageBlocked = true;
			for (let i in this.questions) {
				this.results[this.questions[i].answer]++;
			}
			this.sendStatistics()
			console.log(this.results);
			this.sendResult({ data: this.results })

		},

		initResultsObject() {
			let keys = Object.keys(this.questions[this.currentQuestion].answers)
			for (let i in keys) {
				this.results[keys[i]] = 0;
			}
		},
		changeAnswer(answer) {
			this.questions[this.currentQuestion].answer = answer;
			this.isNeedGiveAnswer = false;
			if (this.currentQuestion < this.questions.length - 1) {
				this.showImage = false;
				setTimeout(() => { this.nextQuestion(); this.showImage = true; }, 400)
			}

		},
		checkAnswer(answer) {
			return this.questions[this.currentQuestion].answer === answer
		},


		nextQuestion() {
			if ((this.currentQuestion < this.questions.length - 1) && (this.questions[this.currentQuestion].answer !== "")) {
				this.currentQuestion++;
			} else {
				this.isNeedGiveAnswer = true;
			}
		},
		prevQuestion() {
			this.isNeedGiveAnswer = false;
			if (this.currentQuestion > 0) {
				this.currentQuestion--;
			}
		},
		fetchData() {
			//axios.get("@/i18n/locales/ru/test_genius_profile.json")
			//  .then(response => {
			//    this.questions = JSON.parse(response.data);
			//		console.log(this.questions)
			//  })
			//  .catch(error => {
			//    console.error(error);
			//  });
		}
	},

}
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
	transition: all .4s ease;
}

.slide-fade-leave-active {
	transition: all .4s ease
}

.slide-fade-enter,
.slide-fade-leave-to {
	opacity: 0;
}

.block__loader {
  width: 100%;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#test {
	font-family: Ubuntu, sans-serif;
}

.page-blocked {
	pointer-events: none;
	opacity: 0.5;
}

.shake {
	animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
	transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
	perspective: 10000px;
}

@keyframes shake {

	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%,
	80% {
		transform: translate3d(2px, 0, 0);
	}

	30%,
	50%,
	70% {
		transform: translate3d(-4px, 0, 0);
	}

	40%,
	60% {
		transform: translate3d(4px, 0, 0);
	}
}

.button_info {
	background: #FFFFFF;
	border-radius: 10px;
	font-weight: 500;
	font-size: 16px;
	line-height: 106%;
	padding: 10px 20px;
  transition: all 0.2s linear;
  border: #FFFFFF 1px solid;
	margin: 7px auto 0;

	//display: flex;
	//align-items: center;
	img {
		margin-right: 5px;
	}

  &:hover {
    border-color: #090D39;
  }
}

.test {
	max-width: 500px;
	margin: 15px auto 0;
	padding: 0;
	display: flex;
	flex-direction: column;


	&__control {
		margin: 15px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: calc(8px + 15 * (100vw / 2560));
		flex-wrap: wrap;
	}


	&__content {
		background-color: #fff;
		border-radius: 10px;
		display: flex;
		flex-direction: column-reverse;
	}

	&__img {
		border-radius: 10px;
		width: 100%;
		max-height: 400px;
		object-fit: cover;
	}

	&__text-content {
		padding: 25px 30px;
		margin-top: auto;
		margin-bottom: auto;
	}

	&__question {
		font-weight: bold;
		font-size: calc(10px + 22 * (100vw / 3500));
		margin-bottom: 15px;
	}

}


.control {
	&__arrow {
		width: 20px;
		height: 20px;

		&.back {
			margin-right: 10px;
		}

		&.next {
			margin-left: 10px;
		}
	}

	&__button {
		border: none;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fff;
		padding: 10px 10px;
		border-radius: 10px;
		transition: all .1s linear;
		white-space: nowrap;
		min-width: 30%;

		&:hover {
			transform: scale(103%);
		}

		&.finish {
			background: #1C2E76;
			font-weight: bold;
			color: white;
		}
	}
}

.plug {
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 30px;

  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    margin: 0 0 15px;

  }

  &__text {
    font-weight: 400;
    font-size: 13px;
    padding: 16px 22px;
    margin: 0;
    border-radius: 20px;
    text-align: justify;
  }

  &__warn {
    font-weight: 400;
    font-size: 13px;
    border-radius: 13px;
    padding: 16px 22px;
    display: flex;
    text-align: justify;

    p {
      margin: 0;
    }

    img {
      margin-right: 20px;
      align-self: start;
    }

  }

  &__button {
    background: #090D39;
    border-radius: 10px;
    border: none;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    padding: 15px 120px;
  }
}

@media (min-width: 445px) {

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 768px) {

  .plug__warn {
    img {
      margin-right: 15px;
    }
  }

  .plug__text {
    //padding: 31px 31px 0;
  }

	.test {
		max-width: 100%;

		&__control {
			margin: 30px 0;
		}

		&__content {
			border-radius: 18px;
		}

		&__img {
			object-fit: cover;
			width: 100%;
			min-height: 400px;
			max-height: 500px;
		}

		&__text-content {
			padding: 15px 20px;
		}
	}

	.control__button {
		border-radius: 18px;
		padding: 10px 30px;

	}

}

// Medium devices (tablets, less than 992px)
@media (min-width: 991.98px) {
	#back {
		order: 1;
	}

	#next {
		order: 3;
	}

	.test__control {
		flex-wrap: nowrap;
		align-items: center;
		margin: 20px 0 0;
		max-height: 500px;
		max-height: 580px;
	}

	.control__button {
		min-width: 27%;
	}

	.test {
		display: block;

		&__img {
			object-fit: cover;
			max-height: 100%;
			width: 40%;
			justify-content: center;
		}

		&__content {

			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
	}

  .plug__warn {
    padding: 16px 22px;
  }

}

@media(min-width: 1200px) {}

@media(min-width: 1440px) {
	.test__img {
		object-fit: cover;
	}


	.test__text-content {
		margin-top: 0;
		margin-bottom: 0;
	}
}
</style>
