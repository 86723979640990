<template>
  <div v-if="isTestDone">
    <div class="container position-relative p-0">
      <div class="user__not-active" v-if="!isEmailVerified">
        <div>
          <EmailVerificationModal :email="email" />
        </div>
      </div>
      <UserBlock>
        <div class="d-lg-flex justify-content-between">
          <div class="user_info d-none d-lg-flex flex-column justify-content-around w-50">
            <div class="mb-3">
              <UserProgressBar :bg-color="this.colorOfType(majorType)" :input="result[majorType]" :max="25"
                :name="this.displayTypeName(majorType)"></UserProgressBar>
            </div>
            <div class="row">
              <div class="col-6">
                <!--Суперсила-->
                <UserTextBlock :text="this.typesInfo[majorType]['superpower']" :title="$t('headers_personal_account.superpower')"
                  :without-padding="true"></UserTextBlock>
              </div>
              <div class="col-6">
                <!--Карьера-->
                <UserTextBlock :text="this.typesInfo[majorType]['career']" :title="$t('headers_personal_account.career_path')"
                  :without-padding="true"></UserTextBlock>
              </div>
            </div>
          </div>
          <div class="user__result" v-if="showStar">
							<NewResultDisplay ref="talent-display" :results-props="result"></NewResultDisplay>
          </div>
        </div>
      </UserBlock>
      <div class="mt-3"></div>

      <div class="d-lg-none ">
        <div class="my-4">
          <UserProgressBar :bg-color="this.colorOfType(majorType)" :input="result[majorType]" :max="25"
            :name="this.displayTypeName(majorType)" :isBgColor="true"></UserProgressBar>
        </div>
        <div class="mb-4">

          <!--Суперсила-->
          <UserTextBlock :text="this.typesInfo[majorType]['superpower']" :title="$t('headers_personal_account.superpower')">
          </UserTextBlock>
        </div>
        <div class="mb-4">
          <!--Карьера-->
          <UserTextBlock :text="this.typesInfo[majorType]['career']" :title="$t('headers_personal_account.career_path')">
          </UserTextBlock>
        </div>
      </div>

      <!--<div class="mb-4">

				<UserTextBlock :text="this.typesInfo[majorType]['main_info']" :title="'О вас:'">
				</UserTextBlock>
			</div>-->

      <div class="d-flex flex-column-reverse  align-items-center flex-lg-row justify-content-between mb-4">
			<div class="user__mainInfo w-100">
				<div class="title">{{$t("headers_personal_account.about_you")}}</div>
				<div class="subtitle" v-html="this.typesInfo[majorType]['main_info']">
				</div>
			</div>
			<img class="user__img mb-4 mb-lg-0" :src="imageUrl" alt="photo">
		</div>

      <div class="mb-4">
        <UserBlockTitle :title="$t('headers_personal_account.working_project')"></UserBlockTitle>
      </div>

      <div class="row mb-4 gy-4 gy-lg-0 mt-lg-4">
        <div class="col-12 col-lg-6">
          <UserBlock :is-green="true">
            <ul>
              <li v-for="text in this.typesInfo[majorType]['project']['adv']">{{ text }}</li>
            </ul>
          </UserBlock>
        </div>
        <div class="col-12 col-lg-6">
          <UserBlock :is-red="true">
            <ul>
              <li v-for="text in this.typesInfo[majorType]['project']['disadv']">{{ text }}</li>
            </ul>
          </UserBlock>
        </div>
      </div>

      <div class="mb-4">
        <UserBlockTitle :title="$t('headers_personal_account.working_team')"></UserBlockTitle>
      </div>

      <div class="row mb-4 gy-4 gy-lg-0 mt-lg-4">
        <div class="col-12 col-lg-6">
          <UserBlock :is-green="true">
            <ul>
              <li v-for="text in this.typesInfo[majorType]['communication']['adv']">{{ text }}</li>
            </ul>
          </UserBlock>
        </div>
        <div class="col-12 col-lg-6">
          <UserBlock :is-red="true">
            <ul>
              <li v-for="text in this.typesInfo[majorType]['communication']['disadv']">{{ text }}</li>
            </ul>
          </UserBlock>
        </div>
      </div>

      <div class="mb-4">
        <UserBlockTitle :title="$t('headers_personal_account.strengths')"></UserBlockTitle>
      </div>
      <div class="user__item d-none d-md-flex mb-4">
        <div v-for="text in this.typesInfo[majorType]['skills']">
          <UserItem>{{ text }}</UserItem>
        </div>
      </div>

      <div class="user__item text-center d-md-none mb-4">
        <div v-for="text in this.typesInfo[majorType]['skills'].slice(0, 5)">
          <UserItem>{{ text }}</UserItem>
        </div>
        <button v-if="!isShowSkills" class="user__more \ btn-block dropdown-toggle mt-2 mb-2" type="button"
          @click="isShowSkills = !isShowSkills">
					{{ $t("headers_personal_account.btn_show_more") }}
        </button>
        <transition-group name="flip-list">
          <div v-for="text in this.typesInfo[majorType]['skills'].slice(5)" :key="text">
            <UserItem v-if="isShowSkills">{{ text }}</UserItem>
          </div>
        </transition-group>
        <button v-show="isShowSkills" class="user__more mt-2 mb-2" type="button" @click="isShowSkills = !isShowSkills">
          {{ $t("headers_personal_account.btn_hide") }}
        </button>
      </div>

      <div class="mb-4">
        <UserBlockTitle :title="$t('headers_personal_account.posotions')"></UserBlockTitle>
      </div>
      <div class="user__item d-none d-md-flex mb-4">
        <div v-for="text in this.typesInfo[majorType]['positions']">
          <UserItem :without-padding ="false">{{ text }}</UserItem>
        </div>
      </div>

      <div class="user__item text-center d-md-none mb-4">
        <div v-for="text in this.typesInfo[majorType]['positions'].slice(0, 5)" class="">
          <UserItem>{{ text }}</UserItem>
        </div>
        <button v-if="!isShowPositions" class="user__more dropdown-toggle mt-2 mb-2" type="button"
          @click="isShowPositions = !isShowPositions">
          {{ $t("headers_personal_account.btn_show_more") }}
        </button>
        <transition-group name="flip-list">
          <div v-for="text in this.typesInfo[majorType]['positions'].slice(5)" :key="text">
            <UserItem v-if="isShowPositions">{{ text }}</UserItem>
          </div>
        </transition-group>
        <button v-show="isShowPositions" class="user__more mt-2 mb-2" type="button"
          @click="isShowPositions = !isShowPositions">
          {{ $t("headers_personal_account.btn_hide") }}
        </button>
      </div>

      <div class="d-xl-none">
        <UserBlockTitle :title="$t('headers_personal_account.secondary_roles')"></UserBlockTitle>
        <div v-for="type in minorTypes">
          <div class="my-4">
            <!--Цвет-->
            <UserProgressBar :bg-color="this.colorOfType(type)" :input="this.result[type]" :max="25"
              :name="this.displayTypeName(type)" :isBgColor="true"></UserProgressBar>
            <button class="user__more  mt-3" @click="redirectToType(type)">{{$t("headers_personal_account.btn_more_detailed")}}</button>
          </div>
          <div class="mb-4">
            <UserTextBlock :text="this.typesInfo[type]['main_info']"></UserTextBlock>
          </div>
        </div>
      </div>

      <div class="d-none d-xl-block">
        <UserBlock>
          <div class="mb-5">
            <h4>{{$t('headers_personal_account.secondary_roles')}}</h4>
          </div>
          <div v-for="type in minorTypes">
            <div class="row ">
              <div class="col-7">
                <UserProgressBar :bg-color="this.colorOfType(type)" :input="this.result[type]" :max="25"
                  :name="this.displayTypeName(type)" :isBgColor="true"></UserProgressBar>
                <button class="user__more float-end mt-3" @click="redirectToType(type)">{{$t("headers_personal_account.btn_more_detailed")}}</button>
              </div>
              <div class="col" v-html="this.typesInfo[type]['main_info']">
              </div>
            </div>
            <hr class="my-5">
          </div>
        </UserBlock>
      </div>

      <div class="d-block mt-3">
        <UserBlockTitle :title="$t('headers_personal_account.see_also')"></UserBlockTitle>
      </div>
      <div class="row gx-2 mt-3">
        <div v-for="type in this.typesName" class="col-12 col-md-6 col-lg-3 p-0 ">
          <UserButtonPureType  @click="redirectToType(type)" :bg-color="this.colorOfType(type)" :name="this.displayTypeName(type)" :type="type"> {{
            type
          }}
          </UserButtonPureType>
        </div>
      </div>
    </div>
  </div>

  <div v-else-if="!hideTalent" class="plug d-flex flex-column align-items-center">
    <div class="row mb-3">
      <div class="col-12 col-lg-6">
        <div class="plug__text bg-white my-auto mx-auto h-100">
          <div class="plug__title">{{ $t("default_information.about_test") }}</div>
          <div>
            <p class="mb-3" v-html="$t('default_information.main') ">
            </p>
          </div>

        </div>
      </div>

      <div class="col-12 col-lg-6 mt-3 mt-lg-0 d-flex flex-column justify-content-between">
        <div>
          <div class="plug__warn w-100 bg-white">
            <img src="@/assets/user/visible.svg" alt="icon">
            <div v-html="$t('default_information.info_visibility_result')"></div>
          </div>
          <div class="plug__warn w-100 bg-white">
            <img src="@/assets/user/clock.svg" alt="icon">
            <div v-html="$t('default_information.info_duration')"></div>
          </div>
          <div class="plug__warn w-100 bg-white">
            <img src="@/assets/user/info_black.svg" alt="icon">
            <div>
              <p class="mb-2" v-html="$t('default_information.add_infotmation')"></p>
            </div>
          </div>
        </div>

        <div>
          <button v-if="$route.params.id == ''" class="plug__button mt-3 mt-lg-0"
            @click="$router.push('/test')">{{ $t("default_information.btn_pass") }}</button>
        </div>
      </div>
    </div>



  </div>
  <div v-else class="d-flex justify-content-center align-items-center mt-5 flex-column">
        <img src="@/assets/lock.svg" style="width: 150px; height: 150px;" alt="">
        <h4>{{ $t('personal_account.user_hidden_result') }}</h4>
  </div>

  <transition name="modal">
    <Modal v-if="isShowRegister" :isTransparentBackground="true"
           :title="''"
           @close="isShowRegister = false">
      <div class="bg-white d-flex flex-column align-items-center justify-content-center text-center" style="border-radius: 20px; padding: 30px 20px;">
        <span>Зарегистрируйтесь, чтобы получить больше информации.</span>

        <button class="modal__button" @click="$router.push('/register')">
          Зарегистрироваться
        </button>
      </div>

    </Modal>
  </transition>
</template>

<script>

import UserBlock from "@/components/User/UserBlock";
import NewResultDisplay from "@/components/User/TalentResultDisplay";
import UserProgressBar from "@/components/User/UserProgressBar";
import UserTextBlock from "@/components/User/UserTextBlock";
import UserBlockTitle from "@/components/User/UserBlockTitle";
import UserItem from "@/components/User/UserItem.vue";
import UserButtonPureType from "@/components/User/UserButtonPureType.vue"
//import api from "@/api";
import typesUtils from '@/mixins/typesUtils'
import EmailVerificationModal from "@/components/User/EmailVerificationModal.vue";
import Modal from "@/components/UI/Modal/Modal.vue";

export default {
  mixins: [typesUtils],
  name: "UserTalentTest",
  components: {
    Modal,
    EmailVerificationModal,
    UserBlockTitle,
    UserItem,
    UserProgressBar,
    UserBlock,
    UserButtonPureType,
    NewResultDisplay,
    UserTextBlock
  },

  methods: {
    redraw(){
      this.$refs["talent-display"].drawCanvas()
    },
    redirectToType(type) {
      if(!this.isShareResult) {
        this.$router.push('/types/' + type)
      } else {
        this.isShowRegister = true;
      }
    }
  },



  data() {
    return {
      isShowPositions: false,
      isShowSkills: false,
      isShowRegister: false
    }
  },

  props: {
    showStar:{
      type: Boolean,
      required: true
    },
    email: {
      type: String,
      required: true
    },
    isEmailVerified: {
      type: Boolean,
      required: true
    },
    result: {
      type: Object,
    },
    typesName: {
      type: Object,
      required: true
    },
    typesInfo: {
      type: Object,
      required: true
    },
    majorType: {
      type: String,
      required: true
    },
    minorTypes: {
      type: Array,
      required: true
    },
    isTestDone: {
      type: Boolean,
      required: true
    },
    hideTalent:{
      type: Boolean,
      required: true
    },
    isShareResult: {
      type:Boolean,
      default: false
    }
  },
  computed: {
    imageUrl() {
      let addres = this.majorType;
      return require(`@/assets/pure_type/${addres}.png`);
    }
  }

}
</script>

<style lang='scss' scoped>
.flip-list-move {
  transition: all 0.5s;
}

.flip-list-item {
  display: inline-block;
  margin-right: 10px;
}

.flip-list-enter-active,
.flip-list-leave-active {
  transition: all 1s;
}


* {
  font-family: 'Ubuntu', sans-serif;
  color: #1E1E1E;
}
.hide-result{
  font-size: 12px;
  color: gray;
  margin-left: 15px;
}
.verify__button {
  font-weight: 500;
  font-size: 16px;
  padding: 5px 20px;
  border: none;
  border-radius: 11px;

  &:hover {
    transform: scale(102%);
  }

  &:active {
    transform: scale(105%);
  }

  &.verify {
    color: white;
    background: #2AB573;
    margin-right: 10px;
  }

  &.restart {
    color: #000000;
    background: #FFFFFF;
  }
}


.btn {
  color: white;
}

.row {
  font-size: 14px;
}

.user {
  &__not-active {
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(234, 237, 246, 0.44);
    backdrop-filter: blur(5.5px);
    width: 100%;
    height: 100%;
    z-index: 99;
    display: flex;
    justify-content: center;
  }

  &__item {
    div {
      margin: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

  }

  &__more {
    color: #1E1E1E;
    font-weight: 500;
    font-size: 16px;
    background: #FFFFFF;
    border-radius: 21.8873px;
    border: none;
    padding: 8px 52px;

    &:hover {
      transform: scale(105%);
    }
  }

  &__mainInfo {
		background-color: #fff;
		border-radius: 21px;
		display: flex;
		flex-direction: column;
		padding: 30px;
		.title {
			font-weight: bold;
			font-size: 16px;
			margin: 0 0 20px ;
		}

		.subtitle {
			font-size: 14px;
		}
	}

  &__img {
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    border-radius: 21px;
  }
}

.plug {

  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    margin: 0 0 15px;

  }

  &__text {
    font-weight: 400;
    font-size: 13px;
    padding: 20px;
    margin: 0;
    border-radius: 20px;
    text-align: justify;
  }

  &__warn {
    font-weight: 400;
    font-size: 13px;
    border-radius: 13px;
    padding: 16px 22px;
    display: flex;
    margin-bottom: 12px;
    text-align: justify;

    p {
      margin: 0;
    }

    img {
      margin-right: 20px;
      align-self: start;
    }

  }

  &__button {
    background: #090D39;
    border-radius: 10px;
    border: none;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    padding: 15px 60px;
    width: 100%;
  }
}

.modal__button {
  font-size: 15px;
  padding: 8px 15px;
  background-color: #090D39;
  margin: 20px 20px 0;
  color: #fff;
  font-weight: 700;
  line-height: 21px;

  border-radius: 8px;
  border: none;
  transition: all .2s linear;

  &:hover {
    transform: scale(1.02);
  }
}


@media (min-width: 576px) {}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .user__item {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .plug__warn {
    img {
      margin-right: 40px;
    }
  }

  .plug__text {
    padding: 31px 50px 50px;
  }
}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .user__result {
    max-width: 35%;
  }

  .user__mainInfo {
			height: 335px;

  }
  .user__img {
    margin-left: 24px;
    max-width: 335px;
  }
}

/*// X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .user__more {
    background: #E7E7E7;
  }

  .user__mainInfo {
		padding: 30px 40px;
	}
}

/*// XX-Large devices (larger desktops, 1400px and up)*/
@media (min-width: 1400px) {

}
</style>
