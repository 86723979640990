<template>
  <div v-if="( !isReady || updatingView)" class="block__loader">
    <loader></loader>
  </div>
	<div v-else class="w-100 container">
    <div id="back" class="my-2">
      <button @click="this.$router.push('/company/' + this.company.id)" class="back__button"><img src="@/assets/back.svg" alt=""> <span>{{ $t("button.back") }}</span></button>
    </div>
		<div class="my-3 team__header">
			<TeamName :is-editing="isEditing" :is-can-rename="canManageUsersInTeam(team)"
				:is-exists="isTeamNameExists" :name="team.name" @editing="isEditing = true; changeView(1)" @rename="rename"></TeamName>
			<div class="my-1">
<!--				<button v-if="isEditing" :class="{ active: view === 1 }" class="view-button" @click="changeView(1)"><img-->
<!--            src="@/assets/renameTeam.svg" alt="renameTeam"></button>-->
				<button v-if="!isEditing" :class="{ active: view === 2 }" class="view-button" @click="changeView(2)"><img src="@/assets/team/icon_1_view.svg" alt=""></button>
				<button v-if="!isEditing" :class="{ active: view === 3 }" class="view-button" @click="changeView(3)"><img src="@/assets/team/icon_2_view.svg" alt=""></button>

			</div>

			<div class="my-1">
        <button v-if="isEditing && canManageTeamInCompany(this.company.id) && !isLocaleEn" class="button bg-white" @click="showSetting">Настройки видимости</button>

        <button v-if="canDeleteTeam(team) && isEditing" class="button btn-red" @click="isDeletingTeam = true">{{
					$t("delete.btn_delete_team") }}
				</button>
<!--				<button v-if="canManageUsersInTeam(team)" class="button"-->
<!--					@click="$router.push('/teams/' + team.id + '/requests')">{{ $t("team.invites") }}-->
<!--				</button>-->
			</div>
		</div>
		<hr>

		<div class="team__content mt-3">
			<div id="talent" class="d-lg-flex justify-content-between">
				<div class="w-100 mx-2 d-flex justify-content-between flex-column">

					<div
						class="display-n  team_users align-items-center d-flex flex-wrap justify-content-center justify-content-lg-start">
						<div v-for="user in sortedUsers" v-if="view === 1" class="team_user my-2 my-lg-3 mx-3 ">

							<UserTableUsers v-if="!isShowSetting" :id="user.id" :key="user.id" :can-manage-role="canDeleteTeam(team)" :draggable="isEditing"
								:firstName="user.firstName" :is-editing="isEditing" :is-verified="user.talentVerified"
								:lastName="user.lastName" :position="user.position" :result="displayTypeName(user.result)"
								:role="user.role" @open="() => { if (!isEditing) $router.push('/users/' + user.id) }"
								@remove="this.deletingUser = user; this.isDeletingUser = true"
								@change-role="changeRolePrepare($event, user)">
							</UserTableUsers>

						</div>
						<TeamView2 v-else-if="view === 2 && !isShowSetting" :users="sortedUsers" />
						<TeamView3 v-else-if="view === 3 && !isShowSetting" :users="users" />
            <CommandVisibilitySettings
                v-if="isShowSetting && isEditing"

                @not-show="showSetting"
                :team-id="team.id"
                :others-sealed="team.areOthersSealed"
                :res-sealed="team.resSealed"
                :team-sealed="team.teamSealed"
                :users-allowed="usersAllowed"

                @close="changeView(1)"
            ></CommandVisibilitySettings>
						<div class="team_user">
							<div v-if="canManageUsersInTeam(team) && canAddUser() && view === 2 && !isShowSetting" class="add" @click="getTeamToken();">
								<img alt="" src="@/assets/add-user-to-team.svg">
							</div>
						</div>
					</div>

					<button id="hidden" v-if="isAvailabilityResult && !isShowSetting" class="modal__button w-50 mx-auto mx-lg-0 " @click="toggleRecomendation">{{
						$t('btn_get_recommendations') }}
					</button>

				</div>

				<!--Total result-->
				<div v-if="!isShowSetting" class="team__talent__wrapper h-100 d-flex flex-column justify-content-center">
					<div class="team__talent position-relative">
						<h4 class="team__talent__title">{{ $t("team.team_profile") }}</h4>
						<div class="team__talent__result">
							<TalentResultDisplay :results-props="teamResult"></TalentResultDisplay>
						</div>
						<DropDownMenuTalent :is-team-page="true" :is-upward-direction="true" class="mt-3"></DropDownMenuTalent>

					</div>
					<button class="modal__button text-center " @click="showConsultation = true">{{ $t("team.request_consult") }}
					</button>
				</div>

			</div>

		</div>


		<div class="mt-3 recomendation" v-if="isShowRecomendation">
			<AutoRecommendations :results-props="Object.assign({}, teamResult)"></AutoRecommendations>
		</div>
	</div>

	<transition name="modal">
		<Modal v-if="showAddInTeam" :title="$t('invite_to_team.invite', { team_name: team.name })" @close="showAddInTeam = false;
		this.isTokenCopied = false;
		this.addingUser.isRequestExists = false;
		this.addingUser.isUserNotExists = false">
			<div class="invite">
				<div v-if="false" class="invite__help">
					{{ $t("invite_to_team.access_link_email") }}
				</div>
				<div v-else class="invite__help">
					{{ $t("invite_to_team.access_link") }}
				</div>
				<div v-if="false" class="invite__email">
					<label>{{ $t("invite_to_team.input_email") }}</label>
					<AddJoinRequestForm :is-request-exists="addingUser.isRequestExists"
						:is-user-not-exists="addingUser.isUserNotExists" @add="addRequest">
					</AddJoinRequestForm>
				</div>
				<div class="invite__link">
					<label>{{ $t("invite_to_team.link") }}</label>
					<div class="invite__link__container">
						<span>{{ getInviteLink }}</span>
						<button class="invite__link__copy" @click="copyTokenLink"
							v-html="isTokenCopied ? $t('invite_to_team.copied') : $t('invite_to_team.copy')"></button>
					</div>
				</div>
			</div>
		</Modal>
	</transition>
	<transition name="modal">
		<Modal v-if="isDeletingTeam" :title="$t('delete.delete_team', { name_team: team.name })"
			@close="isDeletingTeam = false">
			<div class="d-flex justify-content-between align-items-center mt-4">
				<span> {{ $t("delete.are_you_sure") }}</span>
				<span class="btn-delete" @click="deleteTeam()">{{ $t("delete.button") }}</span>
			</div>
		</Modal>
	</transition>
	<transition name="modal">
		<Modal v-if="isDeletingUser"
			:title="$t('delete.delete_user', { first_name: deletingUser.firstName, last_name: deletingUser.lastName })"
			@close="isDeletingUser = false">
			<div class="d-flex justify-content-between align-items-center mt-4">
				<span> {{ $t("delete.are_you_sure") }}</span>
				<span class="btn-delete" @click="deleteUserFromTeam(deletingUser.id)">{{ $t("delete.button") }}</span>
			</div>
		</Modal>
	</transition>
	<transition name="modal">
		<Modal v-if="changingRole.isChanging" :title="getChangeUserTitle" @close="changingRole.isChanging = false">
			<div class="d-flex justify-content-between align-items-center mt-4">
				<span> {{ $t("change_role.are_you_sure") }}</span>
				<span class="btn-delete" @click="changeRole()">{{ $t("change_role.yes") }}</span>
			</div>
		</Modal>
	</transition>
	<PopUpConsultation :is-show-form="showConsultation" :teamId="team.id" @not-show="this.showConsultation = false">
	</PopUpConsultation>

</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import api from '@/api'
import Loader from '@/components/UI/Loader.vue';
import TalentResultDisplay from '@/components/User/TalentResultDisplay.vue';
import CompaniesAndTeamsWrapper from '@/components/UI/CardsBlock.vue';
import UserTableUsers from '@/components/User/UserInTeamCard.vue';
import UserBlock from '@/components/User/UserBlock.vue';
import AddTeamForm from '@/components/Team/AddTeamCard.vue';
import AddJoinRequestForm from '@/components/JoinRequests/AddJoinRequestForm.vue';
import autoritiesMixin from '@/mixins/autoritiesMixin';
import typesUtils from '@/mixins/typesUtils';
import Modal from '@/components/UI/Modal/Modal.vue';
import TeamName from "@/components/Team/TeamName.vue";
import BackAndMainButtons from "@/components/UI/BackAndMainButtons.vue";
import DropDownMenuTalent from "@/components/User/DropDownMenuTalent.vue";
import PopUpConsultation from "@/components/Team/Pop-upConsultation.vue";
import TeamView2 from "@/components/Team/views/TeamView2.vue";
import TeamView3 from "@/components/Team/views/TeamView3.vue";
import AutoRecommendations from '@/components/Team/Auto-recommendations.vue';
import CommandVisibilitySettings from "@/components/Team/seal-company-team/CommandVisibilitySettings.vue";

export default {
	components: {
		TeamView3,
		TeamView2,
		PopUpConsultation,
		DropDownMenuTalent,
		BackAndMainButtons,
		TeamName,
		Modal,
		Loader,
		TalentResultDisplay,
		CompaniesAndTeamsWrapper,
		UserTableUsers,
		UserBlock,
		AddTeamForm,
		AddJoinRequestForm,
		AutoRecommendations,
    CommandVisibilitySettings
	},
	mixins: [autoritiesMixin, typesUtils],


	data() {
		return {
			view: Number(this.$route.query.view) || 2,
			updatingView: false,
			showConsultation: false,
			isReady: false,
			isEditing: Boolean(this.$route.query.setting)|| false,
			isDeletingTeam: false,
			isDeletingUser: false,
			deletingUser: {},
			showAddInTeam: false,
      isLocaleEn: false,
			users: [],
			team: {},
			company: {},
			teamResult: {},
			addingUser: {
				isRequestExists: false,
				isUserNotExists: false,
			},
			changingRole: {
				newRole: '',
				user: {},
				isChanging: false
			},
			token: '',
			isTokenCopied: false,
			isTeamNameExists: false,
			isShowRecomendation: false,
      isShowSetting: Boolean(this.$route.query.setting) || false,
      usersAllowed: {},
		}
	},
	computed: {
		...mapGetters([
			'isAnyLoading',
		]),
		sortedUsers() {
			return this.users.sort((user1, user2) => {
				if (user1.role === "ROLE_TEAM_LEAD") {
					return -1; // user1 должен идти раньше user2
				} else if (user2.role === "ROLE_TEAM_LEAD") {
					return 1; // user1 должен идти после user2
				} else {
					return 0; // порядок не важен
				}
			})
		},
		isAvailabilityResult() {
			let checkVariable = false;
			for (const property in this.teamResult) {
				if (this.teamResult[property] != 0) {
					checkVariable = true;
					break;
				}
			}
			return checkVariable;
		},
		getInviteLink() {
			return 'http://' + window.location.host + '/t/' + this.token
		},
		getChangeUserTitle() {
			const isDown = this.changingRole.newRole === 'ROLE_MEMBER';
			return (isDown ? this.$t('change_role.demote') : this.$t('change_role.promote')) + this.$t('change_role.employee') + this.changingRole.user.firstName + ' ' + this.changingRole.user.lastName + this.$t('change_role.to') + (isDown ? this.$t('change_role.team_member') : this.$t('change_role.team_leader'));
		},
	},
	methods: {
		...mapActions({
			updateTeams: 'AuthModule/updateSelfTeams'
		}),
		toggleRecomendation() {
				this.isShowRecomendation = !this.isShowRecomendation;
				if (this.isShowRecomendation) {
					setTimeout(() => {
						let recomendationElement = document.querySelector(".recomendation");
						recomendationElement.scrollIntoView({ block: "center", behavior: "smooth" });
					}, 100);
				}
		},
    showSetting() {
      this.isShowSetting = !this.isShowSetting;
      // this.$router.replace({ path: '/teams/' + this.team.id, query: { setting: this.isShowSetting } });
    },
		getViewData() {
			this.updatingView = true;
			api.getTeamViewById(this.team.id, this.view).then(r => {
				this.users = r.data;
				this.updatingView = false;
			}).catch(err => {
				this.updatingView = false;
				this.$router.push('/404')
			})
		},
		changeView(view) {
      this.isShowSetting = false;
			this.view = view;
      // this.$router.replace({ path: '/teams/' + this.team.id, query: { view: view } });
		},
		changeRole() {
			let id = this.changingRole.user.id;
			let role = this.changingRole.newRole;
			api.changeRoleInTeam(this.team.id, id, role).then(r => {
				this.changingRole.isChanging = false;
				this.users = this.users.map((user) => (
					user.id === id
						? { ...user, role: role }
						: user
				));
				// this.isEditing = false;

			})
		},
		changeRolePrepare(e, user) {
			if (user.role === 'ROLE_TEAM_LEAD') this.changingRole.newRole = 'ROLE_MEMBER'
			else this.changingRole.newRole = 'ROLE_TEAM_LEAD';
			this.changingRole.user = user;
			this.changingRole.isChanging = true;
		},

		deleteUserFromTeam(userId) {
      this.updatingView = true;
      this.isDeletingUser = false;
			api.deleteUserFromTeam(this.team.id, userId).then(r => {
				this.users = this.users.filter((item) => item.id !== userId);
        this.updatingView = false;
			})
		},
		canAddUser() {
			return this.company.users < this.company.maxUsers;
		},
		deleteTeam() {
			api.deleteTeam(this.team.id).then(this.$router.push('/company/' + this.team.company.id + '?deleted=' + this.team.id))
		},
		async copyTokenLink() {
			this.isTokenCopied = true;
			navigator.clipboard.writeText(this.getInviteLink)
				.catch(err => {
					console.log('Something went wrong', err);
				});
		},
		getTeamToken() {
			this.showAddInTeam = true;
			if (!this.token) {
				api.getTokenForTeam(this.team.id).then(r => this.token = r.data.token)
			}
		},
		addRequest(email) {
			api.createJoinRequest(this.team.id, email).then(r => {
				this.addingUser.isRequestExists = false
				this.addingUser.isUserNotExists = false
				this.invited = true
			}).catch(err => {
				if (err.response.status == 409) this.addingUser.isRequestExists = true
				if (err.response.status == 404) this.addingUser.isUserNotExists = true
			})
		},
		getTeam(id) {
			api.getTeamById(id).then(r => {
				this.team = r.data;
				this.company = r.data.company;
				this.users = this.team.users;
				this.teamResult = r.data.talent;
				this.getViewData();
        this.getUsersAllowedTeam(this.team.id, this.canManageTeamInCompany(this.company.id));
				this.isReady = true;
			}).catch(err => {
				// this.$router.push('/404')
			})

		},

		rename(name) {
			if (this.team.name == name) {
        this.isEditing = false;
        this.changeView(2);
      }
			else {
				api.renameTeam(this.team.id, name)
            .then(this.updatingView = true)
            .then(r => {
              this.updateTeams();
              this.team = r.data;
              this.isTeamNameExists = false
              this.isEditing = false;
              this.changeView(1);
            }).catch(e => {
              this.isTeamNameExists = true;
            })
            .finally(()=>{this.updatingView = false})
			}
		},
    getUsersAllowedTeam(team_id, isAdmin) {
      if(isAdmin) {
        api.getUsersAllowedTeam(team_id)
            .then(r => {
              this.usersAllowed = r.data;
            })
      }

    },
    getLocalLanguage() {
      const currentLocale = this.$i18n.locale;
      //const currentLocale = 'en'
      if (currentLocale === 'en') {
        this.isLocaleEn = true;
      }
    },
	},

  created() {
    this.getLocalLanguage();
  },

	beforeMount() {
		this.getTeam(this.$route.params.id);
	},
	async beforeRouteUpdate(to, from) {
		this.getTeam(to.params.id)

	},
}
</script>

<style lang="scss" scoped>
#back {
  padding-left: 0 !important;

}

.block__loader {
  width: 100%;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.back__button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  color: #070C1F;
  padding: 5px 11px;
  border: none;
  background: #FFFFFF;
  border-radius: 11px;
  transition: 0.2s all linear;

  &:hover {
    transform: scale(98%);
  }

  & img {
    max-height: 20px;
    margin-right: 5px;
  }
}

.view-button {
  background: #f0f0f0;
  font-weight: 500;
  font-size: 14px;
  padding: 5px 20px;
  margin-left: 10px;
  border: none;
  border-radius: 11px;
  transition: 1s linear;

  img {
    height: 20px;
  }

  &:hover {
    border-color: #090D39;
  }

  &:active {
    transform: scale(105%);
    background-color: #fff;
  }

  &.restart {
    color: #000000;
    background: #FFFFFF;
    transition: all 0.2s linear;
    border: 1px solid white;

    &:hover {
      border-color: #090D39;
    }
  }
}

.active {
  transform: scale(105%);
  background-color: #fff;
}

@media (max-width: 488px) {
	.view-button {
		margin-top: 5px;


	}
}

.user-delete {
	text-align: center;
	padding: 35px;
	transition: all .2s linear;
	background: #D9D9D9;
	opacity: 0.5;
	border: 1px dashed #000000;
	border-radius: 42px;

	&.deleting {
		background: #FF9F9F;
		color: red;
		border: 1px dashed #FF0000;
	}
}

.btn-delete {
	color: #e73535;
	cursor: pointer;

	&:hover {
		font-weight: bold;
		color: red;
	}
}

.modal__button {
	font-size: 15px;
	padding: 8px 15px;
	background-color: #090D39;
	margin: 15px 20px;
	color: #fff;
	font-weight: 700;
	line-height: 21px;

	border-radius: 15px;
	border: none;
	transition: all .2s linear;

	&:hover {
		transform: scale(1.02);
	}
}

.button {
	color: #1E1E1E;
	font-weight: 700;
	font-size: 12px;
	background: #E7E7E7;
	border-radius: 15.8873px;
	border: none;
	padding: 8px 15px;
  margin-right: 5px;
  margin-left: 5px;

	&.btn-red {
		background-color: #e73535;
		color: white;
		margin-right: 10px;
	}

	&:hover {
		transform: scale(102%);
	}

	&:active {
		transform: scale(105%);
    background-color: #fff;
	}
}

.invite {

	&__help {
		font-weight: 400;
		font-size: 11px;
		color: #474646;
	}

	&__email {
		margin-top: 24px;
	}

	& label {
		font-size: 12px;
		line-height: 106%;
		color: #000000;
		margin-bottom: 10px;
	}

	&__link {
		margin-top: 30px;

		&__container {
			height: 34px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			background: #E6E6E6;
			border-radius: 11px;
			padding: 4px 4px 5px 10px;

			& span {
				font-size: 10px;
			}
		}

		&__copy {
			background: #0A0F3F;
			border-radius: 11px;
			border: none;
			color: white;
			font-weight: 500;
			font-size: 10.1111px;
			width: 100px;
			height: 100%;
			transition: all .3s linear;

			&:active {
				transform: scale(105%);
			}
		}
	}
}

.add {
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	cursor: pointer;

	&:hover {
		transform: scale(110%);
	}


}

.team {
  &__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

  }

	&__name {
		color: #1C1D22;
		font-weight: 700;
		font-size: 28.0535px;
	}

	&__users {
		display: flex;
		width: 65%;
	}

	&__talent {
		&__wrapper {
			width: 100%;
			margin: 40px auto;
		}

		background-color: #fff;
		border-radius: 57.3879px;
		text-align: center;
		padding: 14px 20px 20px 20px;
		height: 50%;
		align-items: center;

		&__name {
			font-weight: 700;
			font-size: 36.1111px;
			text-transform: uppercase;
		}
	}
}

hr {
	margin: 0;
	height: 2px;
	border: none;
	color: rgba(28, 29, 34, 0.4);
	background-color: rgba(28, 29, 34, 0.4);
}

@media (min-width: 430px) {}

@media (min-width: 576px) {

	.invite {
		&__help {
			font-size: 13px;
		}

		&__email {
			margin-top: 34px;
		}

		& label {
			font-size: 13px;
			margin-bottom: 10px;
		}

		&__link {
			&__container {
				padding: 4px 4px 5px 14px;

				& span {
					font-size: 14px;
				}
			}

			&__copy {
				width: 124px;
			}
		}
	}

	.team__talent__wrapper {
		width: 75%;
	}
}

@media (min-width: 992px) {
	.button {

		font-size: 16px;

		border-radius: 21.8873px;
	}

  .team__header {
    flex-direction: row;
  }

	.team__talent__wrapper {
		width: 45%;
		margin: 0;

		&__title {
			font-weight: bold;
			margin-top: 10px;
			margin-bottom: 20px;
		}
	}


}
</style>
