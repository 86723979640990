<template>
  <div v-if="isAnyLoading || !isPageLoaded" class="block__loader">
    <loader></loader>
  </div>
	<div v-else id="user" class="container my-4">
		<div v-if="$route.params.id" class="w-100">
			<BackAndMainButtons :show-main="false"></BackAndMainButtons>
		</div>
		<div class="user__title">
			<div class="mr-3 user__img">
				<img alt="user" src="@/assets/user.png">
			</div>

			<div class="user__name d-flex  flex-row">
				<div class="d-flex flex-column justify-content-center">
					<h3>{{ user.firstName }} {{ user.lastName }}</h3>
					<h4 class="text-uppercase"> {{ user.position }}</h4>
				</div>

				<a v-if="!$route.params.id" class="user__setting" @click="$router.push('/users/settings')">
					<img alt="setting" src="@/assets/user_setting.svg">
				</a>
				<div v-else-if="isSuperAdmin()" class="user__setting" @click="$router.push('/users/' + user.id + '/settings')">
					<img alt="setting" src="@/assets/user_setting.svg">
				</div>
			</div>
		</div>

		<div class="d-flex flex-column align-items-center flex-lg-row justify-content-md-between p-3 ">
			<div class="user__menu d-flex justify-content-between justify-content-md-center mb-4">
				<div :class="{ 'activeTest': isShowTalentTest }" class="py-3 px-4 user__menu__item "
					@click="changeTest('talent')">
					{{ $t("personal_account.genius_profile") }}

				</div>

				<div v-if="!$route.params.id || !user.hideLevel"
					:class="{ 'activeTest': isShowLevelsTest, 'hideTest': isLocaleEn }"
					class="py-3 px-4 user__menu__item position-relative" @click="changeTest('level')">
					{{ $t("personal_account.levels_consciousness") }}
					<span v-if="user.levelResult == null && isTestDone"
						class=" position-absolute  start-100 translate-middle p-1 bg-danger rounded-circle notification"> </span>
				</div>

			</div>

			<div class="d-flex justify-content-between">
				<div v-if="!$route.params.id && isTestDone" class="  d-flex justify-content-between align-items-center">
					<div class="verify__buttons d-flex align-items-center">
						<Tooltip :content="$t('tooltip.agree_result')" :id="'verify'">
							<button id="verify" v-show="!user.talentResult.verified && isShowTalentTest" class="verify__button verify"
								@click="verifyTalent">{{ $t("personal_account.validity") }}
							</button>
						</Tooltip>

					</div>
				</div>

        <Tooltip :content="$t('tooltip.re_try')" :id="'re_try'" v-if="isShowReTryButton">
          <button id="re_try" class="verify__button restart" @click="restartTest">
            <img src="@/assets/user/icon/icon_re_try.svg" alt="icon_re_try">
          </button>
        </Tooltip>
				<TalentShareMenu v-if="!$route.params.id && isShowTalentTest && isTestDone && !isLocaleEn"
					:results="user.talentResult" :link="user.link"/>
				<Tooltip :content="$t('tooltip.info')" :id="'info-test'">
					<button id="info-test" class="verify__button restart" @click="this.isShowModal = !this.isShowModal">
						<img src="@/assets/user/icon/icon-info.svg" alt="icon_info">
					</button>
				</Tooltip>
			</div>
		</div>


		<UserPopup :isShowModal="this.isShowModal" :isTest="isShowTalentTest" @closeModal="isShowModal = false"></UserPopup>
		<transition name="fade">

			<UserTalentTest v-if="isShowTalentTest" ref="talent-result" :email="user.email"
				:hide-talent="(user.hideTalent && this.$route.params.id !== '')" :is-email-verified="user.status === 'ACTIVE'"
				:isTestDone="this.isTestDone" :majorType="this.majorType" :minorTypes="this.minorTypes"
				:result="user.talentResult" :show-star="isPageLoaded" :typesInfo="this.typesInfo" :typesName="this.typesName">
			</UserTalentTest>


		</transition>
		<transition name="fade">
			<div v-if="isShowLevelsTest">
				<!--<div v-if="!isSecondTestDone" class="mt-5 text-center">
          <h3>Тест в разработке</h3>
        </div>-->
				<UserTestLevelsConsciousnessVue :have-feedback="user.haveTalentFeedback"
					:is-own-page="this.$route.params.id === ''" :level-result="user.levelResult"
					@sendFeedback="this.user.haveTalentFeedback = true"></UserTestLevelsConsciousnessVue>
			</div>

		</transition>

	</div>


	<transition name="modal">
		<Modal v-if="$route.query.token" :title="$t('popup_invitation.confirm_invitation')" @close="$router.push('/users')">
			<hr class="mt-3">
			<div class="my-4">
				<FormTokenDescription v-if="!tokenErrMessage" :token="$route.query.token"></FormTokenDescription>
				<div v-else style="color:red">{{ tokenErrMessage }}</div>
			</div>
			<hr class="mb-3">
			<div v-if="!tokenErrMessage">
				<button class="btn btn-dark accept" type="button" @click="applyToken($route.query.token)">{{
					$t('popup_invitation.confirm')
				}}
				</button>
			</div>
		</Modal>
	</transition>
</template>

<script>
import UserTalentTest from "@/components/User/UserTalentTest.vue";
import UserTestLevelsConsciousnessVue from "@/components/User/UserTestLevelsConsciousness.vue";
import Loader from "@/components/UI/Loader";
import api from "@/api";
import typesUtils from '@/mixins/typesUtils'
import { mapActions, mapGetters } from "vuex";
import FormTokenDescription from "@/components/Form/FormTokenDescription.vue";
import Modal from "@/components/UI/Modal/Modal.vue";
import BackAndMainButtons from "@/components/UI/BackAndMainButtons.vue";
import UserPopup from "@/components/User/UserPopupInfo.vue";
import TalentShareMenu from "@/components/User/TalentShareMenu.vue";
import Vue3Html2pdf from 'vue3-html2pdf'
import autoritiesMixin from "@/mixins/autoritiesMixin";
import { useI18n } from 'vue-i18n';
import Tooltip from "@/components/UI/Tooltip.vue"


export default {
	setup() {
		const { t } = useI18n()
		return { t }
	},
	mixins: [typesUtils, autoritiesMixin],
	name: "User",
	components: {
		TalentShareMenu,
		BackAndMainButtons,
		UserTalentTest,
		Modal,
		Loader,
		FormTokenDescription,
		UserTestLevelsConsciousnessVue,
		UserPopup, Vue3Html2pdf,
		Tooltip
	},
	created() {
		this.getResultGenius();
	},


	computed: {

		...mapGetters({
			isAnyLoading: 'isAnyLoading',
			getCurrentUser: 'AuthModule/getUser',
		}),
		// getRestartLink() {
		// 	return this.isShowTalentTest ? '/test?action=r' : '/level?action=r'
		// },
    isShowReTryButton() {
      if(this.isShowTalentTest ) {
        return !this.$route.params.id && this.isTestDone;
      } else{
        return !this.$route.params.id && this.user.haveTalentFeedback
      }
    }
	},

	beforeMount() {
		this.getUser(this.$route.params.id);
		if (this.$route.query.show === 'level') {
			this.isShowLevelsTest = true;
			this.isShowTalentTest = false;
		}
		this.$store.dispatch('AuthModule/updateSelfJoinRequests');

	},

	methods: {
		changeTest(test) {
			this.$router.push(this.$route.path + '?show=' + test)
			if (test === 'talent')
				setTimeout(() => {
					this.$refs["talent-result"].redraw()
				}, 100)
		},
		verifyTalent() {
			this.onVerifyTalent();
		},
		...mapActions({
			onVerifyTalent: 'AuthModule/onVerifyResult',

		}),
		getUser(id) {
			if (id != '') {
				api.getUserById(this.$route.params.id).then(r => this.setUserInfoByDataAndGetTypesInfo(r.data));
			} else {
				this.isLoading = true;
				this.user = this.getCurrentUser;
				if (this.user.talentResult != null) {
					api.getTypesInfo().then(r => {
						//this.typesInfo = r.data;
						this.isLoading = false;
						this.typesName = Object.keys(this.typesInfo);
						this.getDisplayingTypes(this.user.talentResult);
						this.isPageLoaded = true;
					})
				} else {
					this.isTestDone = false;
					this.isPageLoaded = true;
				}
			}
		},
		setUserInfoByDataAndGetTypesInfo(data) {
			if (data.talentResult != null) {
				api.getTypesInfo().then(r => {
					this.user = data;
					if (this.user.levelResult != null)
						this.user.levelResult.result = JSON.parse(this.user.levelResult.result);
					//this.typesInfo = r.data;
					this.isLoading = false;
					this.typesName = Object.keys(this.typesInfo);
					this.getDisplayingTypes(this.user.talentResult);
					this.isPageLoaded = true;

				})
			} else {
				this.isTestDone = false;
				this.user = data;
				this.isPageLoaded = true;

			}

		},


		getDisplayingTypes(results) {
			let keysSorted = Object.keys(results).sort(function (a, b) {
				return results[b] - results[a];
			})
			this.majorType = keysSorted[0];
			this.minorTypes[0] = keysSorted[1];
			this.minorTypes[1] = keysSorted[2];
		},


		applyToken(token) {
			api.applyToken(token).then(r => {
				this.$store.dispatch('AuthModule/updateSelfCompanies');
				this.$store.dispatch('AuthModule/updateSelfTeams');
				this.$store.dispatch('AuthModule/updateAdminCompanies');
				this.$router.push('/users');
			})
				.catch(err => {
					if (err.response.status == 409) this.tokenErrMessage = this.$t("popup_invitation.already_accepted_invitation")
					else this.tokenErrMessage = this.$t("popup_invitation.not_found")
				})
		},
		getResultGenius() {
			const currentLocale = this.$i18n.locale;
			//const currentLocale = 'en';
			this.typesInfo = require(`@/i18n/locales/${currentLocale}/result_genius.json`);
			if (currentLocale == 'en') {
				this.isLocaleEn = true;
			}
		},

    restartTest() {
      let path;
      this.isShowTalentTest ? path = '/test?action=r' : path = '/level?action=r';
      this.$router.push(path);
    }
	},

	beforeRouteUpdate(to, from) {

		if (to.params.id) {
			this.getUser(to.params.id);
		} else {
			this.getUser('');
		}
		if (to.query.show === 'level') {
			this.isShowLevelsTest = true;
			this.isShowTalentTest = false;
		} else {
			this.isShowLevelsTest = false;
			this.isShowTalentTest = true;
		}
	},


	data() {
		return {
			isTestDone: true,
			isSecondTestDone: false,
			typesInfo: {},
			majorType: '',
			minorTypes: [],
			typesName: [],
			user: {},
			tokenErrMessage: '',
			isPageLoaded: false,
			isShowTalentTest: true,
			isShowLevelsTest: false,
			isShowModal: false,
			isLocaleEn: false,
		}
	},


}
</script>

<style lang='scss' scoped>
.btn.btn-dark.accept {
	background-color: #0A0F3F;

	&:hover {
		transform: scale(1.02);
	}
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.block__loader {
  width: 100%;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
	font-family: 'Ubuntu', sans-serif;
	color: #1E1E1E;
}

.verify__button {
	font-weight: 500;
	font-size: 14px;
	padding: 5px 20px;
	margin-left: 10px;
	border: none;
	border-radius: 11px;
	transition: 1s linear;

	&:hover {
		border-color: #090D39;
	}

	&:active {
		transform: scale(105%);
	}

	&.verify {
		color: white;
		background: #2AB573;
	}

	&.restart {
		color: #000000;
		background: #FFFFFF;
		transition: all 0.2s linear;
		border: 1px solid white;

		&:hover {
			border-color: #090D39;
		}
	}
}

.btn {
	color: white;
}

.user {

	&__title {
		display: flex;

		img {
			border-radius: 22px;
			margin-right: 15px;
			object-fit: contain;
			max-width: 120px;
			max-height: 90px;
		}
	}

	&__name {
		width: 100%;
		display: flex;
		justify-content: space-between;
		padding: 10px 15px;
		background-color: #fff;
		border-radius: 22px;
		font-weight: 900;
		color: #1E1E1E;

		h3 {
			font-size: 15px;
		}

		h4 {
			font-size: 10px;
		}
	}

	&__setting {
		display: flex;
		align-items: center;

		& img {
			cursor: pointer;
			transition: all 0.3s linear;

			&:hover {
				transform: rotate(90deg) scale(1.1);
			}
		}

	}

	&__menu {
		display: flex;
		font-size: 13px;

		&__item {
			color: rgba(28, 29, 34, 0.3);
			border-bottom: 2px solid rgba(28, 29, 34, 0.3);
			cursor: pointer;
			transition: all 0.2s ease-in;

			&:hover {
				color: rgba(28, 29, 34, 0.6);
				border-bottom: 2px solid rgba(28, 29, 34, 0.6);
			}

			&.activeTest {
				color: #1C1D22;
				border-bottom: 2px solid #1C1D22;
			}

			&.hideTest {
				display: none;
			}
		}
	}

}

@media (min-width: 576px) {
	.user__name {
		h3 {
			font-size: 20px;
		}

		h4 {
			font-size: 13px;
		}
	}

	.verify__button {
		&.verify {
			margin-right: 40px;
		}
	}
}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {

	.user__name {
		padding: 10px 25px;

		h3 {
			font-size: 22px;
		}

		h4 {
			font-size: 15px;
		}
	}

	.user__menu {
		font-size: 16px;
	}
}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {}

/*// X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {}

/*// XX-Large devices (larger desktops, 1400px and up)*/
@media (min-width: 1400px) {
	#user {
		padding: 0 40px;
	}
}
</style>
