<template>
<div  id="main" class="">
		<div class="wrapper">
			<div class="container register">
        <div v-if="action == 'pswd_changed'" class="alert alert alert-secondary text-center" :class="{hideText: isLocaleEn}" >Вы успешно сменили пароль. Пожалуйста,
          войдите с новым паролем
        </div>
				<div class="row">
					<div class="col d-flex justify-content-center">
						<p class="registerTitle h3">
							{{ $t("login.title") }}
						</p>
					</div>
				</div>
				<div class="row my-3">
					<div class="col d-flex justify-content-center">
						<form @submit.prevent="login" id="formRegisterPage">
							<div class="row">
								<div class="col my-1">
									<label for="name">EMAIL</label><br>
									<input @input="resetLoginError" v-model="email" class="registerForm" type="email" name="email" id="email" placeholder="E-mail" autofocus required>
								</div>
							</div>
							<div class="row">
								<div class="col my-1 password">
									<label for="name" style="text-transform:uppercase"> {{$t("login.password")}}</label><br>
									<input @input="resetLoginError"  v-model="password" class="registerForm" :type="showPassword ? 'text' : 'password'" name="pass" id="password-input" :placeholder="$t('login.password')" required>
									<a href="#" class="password-control" :class="{view : showPassword}" @click="show_hide_password()"></a><br>
								</div>
                <div class="toLogin text-end" :class="{hideText: isLocaleEn}">
                  <a @click="this.$router.push('/password-recovery')">Восстановить пароль</a>
                </div>
							</div>
              <div v-if="isLoginError" class="row">
                <p class="error-label">{{$t("login.error")}}</p>
              </div>
							<div class="row">
								<div class="col my-3 d-flex justify-content-center">
                  <loader v-if="isLoading"/>
									<input :disabled="isLoginError" v-else class="registerButton" type="submit" :value="$t('login.sign_in')">
								</div>
							</div>
						</form>
					</div>
				</div>
				<div class="row">
					<div class="col d-flex justify-content-center">
						<p class="toLogin">{{ $t("login.question") }} <a @click="this.$router.push('/register')"> {{$t("login.link_to_register")}}</a></p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import Loader from "@/components/UI/Loader.vue";

export default {
  name: "NewLogin",
  components: {Loader},
  data(){
    return{
      showPassword: false,
      email: "",
      password: "",
      action: this.$route.query.action,
      isLocaleEn: false,
    }
  },
  created() {
    this.getResultGenius();
  },
  computed: {
    ...mapGetters(
      {
        isLoginError: 'AuthModule/isLoginError',
        isLoading: 'isAnyLoading'
      }
    ),
  },
   mounted() {
    this.resetLoginError();

  },
  methods: {
    ...mapMutations({
      resetLoginError: 'AuthModule/resetLoginError'
    }),
    ...mapActions({
      onLogin: 'AuthModule/onLogin'
    }),
    show_hide_password(){
      this.showPassword = !this.showPassword
    },
    login(){
      console.log("onLogin");
      this.onLogin({
        email: this.email,
        password: this.password,
        redirect: true
      })
    },
    getResultGenius() {
      const currentLocale = this.$i18n.locale;

      if (currentLocale == 'en') {
        this.isLocaleEn = true;
      }

    }
  }
}
</script>

<style scoped>
#main{
   position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
  background-image: url(@/assets/dark_back.png);
  width: 100%;
  height: 100%;
  color: white;
}

.hideText {
  display: none;
}

.register{
	font-family: Ubuntu;
	font-weight: 400;
	margin-top: 100px;
	margin-bottom: 50px;
}
form{
	width: 40%;
}
.error-label{
  text-align: center;
  color:red;
}
.registerForm{
	width: 100%;
	color: white;
	padding: 15px;
	background: transparent;
	border: 1px #6B6B6B solid;
  border-radius: 20px;
}
.registerForm:focus{
	border: 1px #202FD4 solid;
	outline: none;
	transition: 0.2s;
}
.registerButton{
	width: 100%;
	display: block;
	outline: none;
	padding: 15px 20px;
	background: #2835C1;
	border: none;
	border-radius: 15px;
	text-decoration: none;
	color: #F6F6F5;
	font-size: 20px;
	font-family: Ubuntu;
	font-weight: 400;
	transition: 0.2s;
}
.registerButton[disabled]{
  background-color: gray;
}
.registerButton[disabled]:hover{
  background-color: gray;
  box-shadow: none;
}
.registerButton:hover{
	box-shadow: 0px 0px 20px 0.5px #0015FF;
	background: #0017EF;
	transition: 0.2s;
}
.registerButton:active{
	background: #0014CC;
	transition: 0.2s;
}
label{
	margin-left: 15px;
	margin-bottom: 10px;
	font-weight: 700;
}
.password{
	position: relative;
}
.password-control{
	position: absolute;
	top: 53px;
	right: 30px;
	display: inline-block;
	width: 20px;
	height: 20px;
	background: url(@/assets/password/view_icon.png) 0 0 no-repeat;
}
.password-control.view{
	background: url(@/assets/password/no_view_icon.png) 0 0 no-repeat;
}

.message {
	transition: 0.2s;
	font-weight: 400;
	height: 0px;
	margin-left: 15px;
  margin-top: 15px;
  overflow: hidden;
}
.messageShow {
	transition: 0.2s;
	font-weight: 500;
	height: 70px;
	margin-left: 15px;
  margin-top: 15px;
}
.message p {
	margin-bottom: 0px;
  font-size: 14px;
}
.valid {
  display: none;
}
.invalid {
	display: block;
  color: red;
}

.toLogin>a{
	color: #2835C1;
	text-decoration: none;
}
.toLogin>a:hover{
	text-decoration: underline;
}
@media (min-width: 100px) and (max-width: 767px){
  form{
		width: 70%;
	}
	.registerButton{
		padding: 15px 5px;
	}
}
@media (max-width: 408px){
  .messageShow {
		height: auto;
	}
}
</style>