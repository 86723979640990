import { createRouter, createWebHistory } from "vue-router/dist/vue-router";
import TestPage from "@/pages/Test";
import TestLevelsConsciousnes from "@/pages/TestLevelsConsciousnes"
import User from "@/pages/User";
import PureTypes from "@/pages/PureTypeDesc.vue"
import PureLevel from '@/pages/PureLevelDesc.vue'
import store from "@/store";
import Companies from '@/pages/Companies'
import Company from '@/pages/Company'
import Teams from '@/pages/Teams'
import Team from '@/pages/Team'
import NotFound from "@/pages/NotFound";
import UserJoinRequests from '@/pages/UserJoinRequests'
import TeamJoinRequests from '@/pages/TeamJoinRequests'
import api from "@/api";
import ErrorConnection from "@/pages/ErrorConnection";
import Forbbiden from "@/pages/Forbbiden";
import InternalError from "@/pages/InternalError";
import Types from "@/pages/Types.vue";
import PasswordRecovery from "@/pages/PasswordRecovery.vue";
import EmailVerification from "@/pages/EmailVerification.vue";
import Admin from "@/pages/Admin.vue";
import LevelTestDisplay from "@/components/Test/LevelTestDisplay.vue";
import UserSettingsByAdmin from "@/pages/UserSettingsByAdmin.vue";
import NewLogin from "@/pages/NewLogin.vue";
import NewRegister from "@/pages/NewRegister.vue";
import CompanySettings from "@/pages/CompanySettings.vue";
import PaymentSuccessResult from "@/pages/PageSuccessPaymentResult.vue";
import PaymentFailedResult from "@/pages/PagePaymentFailedResult.vue";
import PageRsreu from "@/pages/PageRsreu";
import LandingProfileGenius from "@/pages/LandingProfileGenius.vue";
import PageRegistrationCompany from "@/pages/PageRegistrationCompany.vue";
import Landing from "@/pages/Landing.vue";
import UserShareResult from "@/pages/UserShareResult.vue";

const ifAuthenticated = (to, from, next) => {
	if (store.getters["AuthModule/isAuthenticated"]) {
		next()
		return
	}
	next('/login')
}
const ifSuperAdmin = (to, from, next) => {
	if (store.getters["AuthModule/getRole"] == 'ROLE_SUPER_ADMIN') {
		next()
		return;
	}
	next('/403')
}
const ifNotAuthenticated = (to, from, next) => {
	if (!store.getters["AuthModule/isAuthenticated"]) {
		next()
		return
	}
	next('/users')
}
const checkForToken = (to, from, next) => {
	let token = to.params.token
	api.getTokenDesctiption(token).then(r => {
		if (store.getters["AuthModule/isAuthenticated"]) {
			router.push('/users?token=' + token)
		} else {
			router.push('/register?token=' + token)
		}
	}).catch(err => router.push('/users'));

}

const routes = [
	{
		path: '/',
		component: Landing,
		// beforeEnter(to, from, next) {
		// 	if (process.env.NOD === 'production')
		// 		window.location.href = '/'
		// }
	},
	{
		path: '/verify',
		component: EmailVerification
	},
	{
		path: '/password-recovery',
		component: PasswordRecovery,
		beforeEnter: ifNotAuthenticated
	},
	{
		path: '/genius-profile',
		component: LandingProfileGenius,
	},
	{
		path: '/admin-panel',
		component: Admin,
		beforeEnter: ifSuperAdmin
	},
	{
		path: '/requests',
		component: UserJoinRequests,
		beforeEnter: ifAuthenticated
	},
	{
		path: '/ERR_NETWORK',
		component: ErrorConnection
	},
	{
		path: '/403',
		component: Forbbiden
	},
	{
		path: '/500',
		component: InternalError
	},
	{
		path: '/company',
		component: Companies,
		beforeEnter: ifAuthenticated
	},
	{
		path: '/company/:id/settings',
		component: CompanySettings,
		beforeEnter: ifAuthenticated
	},
	{
		path: '/teams/:id',
		component: Team,
		beforeEnter: ifAuthenticated
	},
	{
		path: '/teams/:id/requests',
		component: TeamJoinRequests,
		beforeEnter: ifAuthenticated
	},
	{
		path: '/company/:id',
		component: Company,
		beforeEnter: ifAuthenticated
	},
	{
		path: '/teams',
		component: Teams,
		beforeEnter: ifAuthenticated
	},
	{
		path: '/display',
		component: LevelTestDisplay
	},
	{
		path: '/register',
		component: NewRegister,
		beforeEnter: ifNotAuthenticated
	},
	{
		path: '/test',
		component: TestPage,
		beforeEnter: ifAuthenticated
	},
	{
		path: '/level',
		component: TestLevelsConsciousnes,
		beforeEnter: ifAuthenticated
	},
	{
		path: '/users/:id?',
		component: User,
		beforeEnter: ifAuthenticated
	},
	{
		path: '/users/:id/settings',
		component: UserSettingsByAdmin,
		beforeEnter: ifAuthenticated
	},
	{
		path: '/users/settings',
		component: () => import('../pages/UserProfile.vue'),
		beforeEnter: ifAuthenticated
	},
	{
		path: '/types',
		component: Types,
		beforeEnter: ifAuthenticated
	},
	{
		path: '/types/:id',
		component: PureTypes,
		beforeEnter: ifAuthenticated
	},
	{
		path: '/levels/:id',
		component: PureLevel,
		beforeEnter: ifAuthenticated
	},
	{
		path: '/login',
		component: NewLogin,
		beforeEnter: ifNotAuthenticated
	},
	{
		path: '/success-payment',
		component: PaymentSuccessResult,
	},
	{
		path: '/failed-payment',
		component: PaymentFailedResult,
	},
	{
		path: '/company-registration',
		component: PageRegistrationCompany,
	},
	{
		path: '/rsreu-list',
		component: PageRsreu,
	},
	{
		path: '/404',
		component: NotFound
	},
	{
		path: '/t/:token',
		beforeEnter: checkForToken
	},
	{
		path: '/:pathMatch(.*)*',
		redirect: '/404'
	},
	{
		path: '/user/share/:token',
		component: UserShareResult,
	}


]

const router = createRouter({
	mode: 'history',
	routes,
	history: createWebHistory()
})

router.beforeEach((to, from, next) => {
	window.scrollTo(0, 0);
	next();
});

export default router

