<template>
  <div class="user__not-active__activate" style="blu">
    <div>
      <h4>Результаты почти готовы, остался последний шаг!</h4>
      <h5>Активируйте ваш аккаунт. <br> Инструкции уже отправлены на почту {{ email }}</h5>
      <loader v-if="isSending"></loader>
      <p v-else><span v-if="sendTimer == 0">Не получили письмо? <span class="link" @click="sendVerification">Отправить еще раз </span></span>
        <span v-else>Письмо отправлено. Вы сможете повторить попытку через {{sendTimer}} секунд.</span>
        <br>
        Если письмо не приходит, проверьте папку спам.</p>
    </div>

  </div>
</template>
<script>

import Loader from "@/components/UI/Loader.vue";
import api from "@/api";
import {mapActions} from "vuex";

export default {
  name: 'EmailVerificationModal',
  components: {Loader},
  props: {
    email: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isSending: false,
      sendTimer: 0
    }
  },
  mounted() {
    this.sendTimer = sessionStorage.getItem("timer") || 0;
    if (this.sendTimer > 0) {
      setInterval(() => this.countTimer(), 1000)
    }
  },
  methods: {
    ...mapActions({
      setLoadingFalse: 'finishLoad'
    }),
    sendVerification() {
      this.isSending = true;
      api.sendVerificationEmail().then(r => {
        this.startEmailTimer();
        this.isSending = false
        this.setLoadingFalse();
      })
    },
    startEmailTimer() {
      this.resetTimer();
      this.sendTimer = 180;
      setInterval(() => this.countTimer(), 1000)
    },
    countTimer() {
      if (this.sendTimer > 0) {
        this.sendTimer--;
        sessionStorage.setItem("timer", this.sendTimer);
      }
    },
    resetTimer() {
      sessionStorage.setItem("timer", 0);
      const highestId = window.setTimeout(() => {
        for (let i = highestId; i >= 0; i--) {
          window.clearInterval(i);
        }
      }, 0);
    }
  }
}
</script>
<style lang='scss' scoped>

.user {
  &__not-active {
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(234, 237, 246, 0.44);
    //TODO: Сейчас можно убрать через css. Исправить
    backdrop-filter: blur(5.5px);
    width: 100%;
    height: 100%;
    z-index: 99;
    display: flex;
    justify-content: center;

    &__activate {
      text-align: center;
      background: #FFFFFF;
      border-radius: 25px;
      margin-top: 50px;
      padding: 23px 30px;

      & h4, h5 {
        font-weight: bold;
        margin-bottom: 0;
        font-size: 15px;
      }

      & h5 {
        margin-top: 30px;
      }

      & p {
        margin-top: 27px;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 14px;

        & .link {
          cursor: pointer;
          color: #2835C1;

          &:hover {
            color: #212c87;
          }
        }
      }
    }
  }

}

@media (min-width: 576px) {
}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .user {
    &__not-active {


      &__activate {
        text-align: center;
        background: #FFFFFF;
        border-radius: 25px;
        margin-top: 100px;
        padding: 43px 50px;

        & h4, h5 {
          font-weight: bold;
          margin-bottom: 0;
          font-size: 18px;
        }

        & h5 {
          margin-top: 30px;
        }

        & p {
          margin-top: 27px;
          margin-bottom: 0;
          font-size: 14px;
          line-height: 14px;

          & .link {
            cursor: pointer;
            color: #2835C1;

            &:hover {
              color: #212c87;
            }
          }
        }
      }
    }

  }
}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
}

/*// X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
}

/*// XX-Large devices (larger desktops, 1400px and up)*/
@media (min-width: 1400px) {


}
</style>