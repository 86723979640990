<template>
  <h3>Реактивация клиентов</h3>
  <div class="container-fluid p-0" style="width: 1200px">
    <table class="table table-striped table-bordered border-primary ">
      <thead>
      <tr>
        <th scope="col">Название</th>
        <th scope="col">Телефон</th>
        <th scope="col">Представитель</th>
        <th scope="col">по Профилю гения</th>
        <th scope="col">по Уровням осознанности</th>
        <th scope="col">Новые сотрудники</th>
      </tr>
      </thead>
      <tbody>
      <loader v-if="isLoading"></loader>
      <tr v-else v-for="team in teams">

        <td>
          <router-link class="link" :to="'/company/' +team.id">{{ team.name }}</router-link>
        </td>

        <td>{{ team.phone || "Нет данных" }}</td>
        <td>{{ team.adminName || "Нет данных" }}</td>
        <td>{{ team.hasGPResult || "Нет данных" }}</td>
        <td>{{team.hasCLResult || "Нет данных" }}</td>
        <td>{{team.hasNewEmployee || "Нет данных" }}</td>

      </tr>
      </tbody>
    </table>

    <div class="d-flex align-items-center">
      <div class="mx-2">
        <label for="startDate">Start Date:</label>
        <input type="date" id="startDate" v-model="startDate">
      </div>

      <div class="mx-2">
        <label for="endDate">End Date:</label>
        <input type="date" id="endDate" v-model="endDate">
      </div>

      <button class="mx-2 btn btn-primary" @click="loadTeamData">Загрузить</button>
    </div>
  </div>


<!--  <div>-->
<!--    <nav aria-label="...">-->
<!--      <ul class="pagination">-->
<!--        <li :class="{disabled : offset == 0}" class="page-item">-->
<!--          <a class="page-link" @click="getUsers(this.limit, &#45;&#45;offset)">Previous</a>-->
<!--        </li>-->
<!--        <li v-for="page in totalPages" :class="{active : offset == page - 1}" aria-current="page" class="page-item">-->
<!--          <a class="page-link" @click="getUsers(this.limit, page-1)">{{ page }}</a>-->
<!--        </li>-->
<!--        <li :class="{disabled : offset == totalPages - 1}" class="page-item ">-->
<!--          <a class="page-link" @click="getUsers(this.limit, ++offset)">Next</a>-->
<!--        </li>-->
<!--      </ul>-->
<!--    </nav>-->
<!--  </div>-->
</template>

<script>
import Loader from "@/components/UI/Loader.vue";
import api from "@/api";
import autoritiesMixin from '@/mixins/autoritiesMixin'

export default {
  name: "UserSelfTable",
	mixins: [autoritiesMixin],
  components: {Loader},
  props: {
    teamsProps: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      teams: this.teamsProps,
      // offset: this.usersProps.number,
      // limit: this.usersProps.size,
      // totalPages: this.usersProps.totalPages,
      isLoading: false,
      startDate: null,
      endDate: null,
    }

  },
  watch:{
    isTyping(newVal){
      if(!newVal){
        this.getUsers(this.limit,this.offset)
      }
    }
  },
  methods: {
    formatedDate(date) {
      return date.slice(0, 10);
    },
    loadTeamData() {
      if(this.startDate < this.endDate) {
        this.isLoading = true;
        api.getRecoveredTeamByDate(this.startDate, this.endDate)
            .then(r => {this.teams = r.data; this.isLoading = false;})
      }
    }
  }
}
</script>

<style scoped lang="scss">
.link {
  color: black;
  text-decoration: none;

  &:hover {
    font-weight: bold;
  }
}

.admin {
	background-color: rgb(255, 255, 255);
}

.notAdmin{
	background-color: grey;
	color: #FFFFFF
}
</style>
