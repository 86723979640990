<template>
  <div v-if="isLoading" class="block__loader">
    <loader></loader>
  </div>
	<div v-else class="w-100">
		<div v-if="isSuperAdmin()" class="d-flex flex-column flex-md-row justify-content-between">
			<div>
			<button class="m-3 btn btn-primary" @click="getAdminToken">Получить ссылку для добавления админа</button>
			{{ adminTokenLink }}
		  </div>
			<div>
				<button class="btn btn-danger m-3" @click="isDeleteCompany = true">Удалить компанию</button>
			</div>
		</div>
		<div v-if="isSuperAdmin()" class="access-form">
      <div class="access-form__operations">
        <div class="mx-3 mb-2 mb-md-0">
          <span>Добавить доступов: </span>
          <input v-model="addingAccesses.value" min="0" placeholder="Сколько добавить" type="number"
            @input="this.addingAccesses.error = '';">
          <div v-if="addingAccesses.error" class="error">
            <p class="mx-1">{{ addingAccesses.error }}</p>
          </div>
          <button class="btn btn-primary mx-3" @click="isAccess = true, isAddAccess = true">Добавить</button>
        </div>
        <div class="mx-3">
          <span>Удалить доступы: </span>
          <input v-model="reduceAccesses.value" min="0" placeholder="Сколько добавить" type="number"
            @input="this.reduceAccesses.error = '';">
          <div v-if="reduceAccesses.error" class="error">
            <p class="mx-1">{{ reduceAccesses.error }}</p>
          </div>
          <button class="btn btn-primary mx-3" @click="isAccess = true, isAddAccess = false">Удалить</button>
        </div>
      </div>

      <div id="back" class="container">
        <button @click="this.$router.push('/company')" class="back__button"><img src="@/assets/back.svg" alt=""> <span>{{ $t("button.back") }}</span></button>
      </div>

      <div v-if="canManageTeamInCompany(this.company.id)" class="container d-flex justify-content-between mt-3">
        <AccessLeftNotification :id="company.id" :accesses="company.maxUsers - company.users"></AccessLeftNotification>
      </div>
		</div>



		<div class="company__panel container">
			<div class="company__info panel__item" :class="{'isCanManage': !canManageTeamInCompany(this.company.id)}">
				<div class="company__contacts" style="display: inline-block;">
					<div class="company__title">
						{{ company.name }}
					</div>
					<div v-if="canManageTeamInCompany(this.company.id)" class="company__phone margins">
						{{ $t('setting_company.employees') }} {{ company.users }}
					</div>
          <div v-if="canManageTeamInCompany(this.company.id)" class="company__phone">
            {{ company.phone }}
          </div>
				</div>
				<div v-if="canManageTeamInCompany(this.company.id)" class="company__settings"
					@click="$router.push('/company/' + company.id + '/settings')">
					<img alt="setting" src="@/assets/user_setting.svg">
				</div>
			</div>
			<div v-if="canManageTeamInCompany(this.company.id)" class="company__admin panel__item" style="display: inline-block;">
				<div class="subtitle"> {{ $t('setting_company.admin') }}</div>
				<div class="admin__contacts" v-if="company.representative">
					<div class="fw-bold margins">{{ adminFullName }}</div>
					<div v-if="admin.phone" class="margins">{{ admin.phone }}</div>
					<div class="">{{ admin.email }}</div>
				</div>
				<div v-else>
					{{ $t('setting_company.undefined') }}
				</div>
			</div>
      <PromoCode v-if="canManageTeamInCompany(this.company.id) && !isLocaleEn" :promo-code="this.company.promoCode"></PromoCode>
		</div>

		<CompanyAndTeamWrapper>

			<div v-for="team in company.teams" class="item">
				<TeamCard :count="team.count" :name="team.name" @click="this.$router.push('/teams/' + team.id)">
				</TeamCard>
			</div>
			<div v-show="!isLoading && canManageTeamInCompany(company.id)" class="item opacity-75">
				<AddTeamForm :isExists="isAddingTeamExists" @add="addTeam"></AddTeamForm>
			</div>
		</CompanyAndTeamWrapper>
	</div>

	<transition name="modal">
		<Modal v-if="isDeleteCompany"
			:title="'Удалить компанию '+ company.name"
			@close="isDeleteCompany = false">
			<div class="d-flex justify-content-between align-items-center mt-4">
				<span> {{ $t("delete.are_you_sure") }}</span>
				<span class="btn-delete" @click="deleteCompany(this.company.id)">{{ $t("delete.button") }}</span>
			</div>
		</Modal>
	</transition>


	<transition name="modal">
		<Modal v-if="isAccess"
			:title="((isAddAccess)?'Добавить в компанию ':'Удалить из компании ') + company.name + ' ' + ((isAddAccess)?addingAccesses.value : reduceAccesses.value) + ' доступов'"
			@close="isAccess = false">
			<div class="d-flex justify-content-between align-items-center mt-4">
				<span> {{ $t("delete.are_you_sure") }}</span>
				<span class="btn-delete" @click="actionWithAccess(isAddAccess)">Да</span>
			</div>
		</Modal>
	</transition>
</template>
<script>
import api from '@/api'
import AddTeamForm from '@/components/Team/AddTeamCard.vue';
import CompanyAndTeamWrapper from '@/components/UI/CardsBlock.vue'
import TeamCard from '@/components/Team/TeamCard.vue';
import Loader from '@/components/UI/Loader.vue';
import autoritiesMixin from '@/mixins/autoritiesMixin'
import { mapGetters } from 'vuex';
import TeamName from "@/components/Team/TeamName.vue";
import AccessLeftNotification from "@/components/Company/AccessesLeftNotification.vue";
import Modal from '@/components/UI/Modal/Modal.vue';
import PromoCode from '@/components/Company/PromoCode.vue';


export default {
	components: { AccessLeftNotification, TeamName, Loader, TeamCard, AddTeamForm, CompanyAndTeamWrapper, Modal, PromoCode },
	mixins: [autoritiesMixin],

	data() {
		return {
			company: {},
			isAddingTeamExists: false,
			adminTokenLink: '',
			deletedTeam: this.$route.query.deleted,
			isDeleteCompany: false,
			isAddAccess: false,
			isAccess: false,
      isLocaleEn: false,
			addingAccesses: {
				value: 0,
				error: ""
			},
			reduceAccesses: {
				value: 0,
				error: ""
			},
			isLoading: false,
		}
	},

	methods: {
		addAccesses() {
			if (this.addingAccesses.value < 1) {
				this.addingAccesses.error = 'Введите больше 0'
			} else {
				this.addingAccesses.error = '';
				this.isLoading = true;
				api.addAccessesToCompany(this.company.id, this.addingAccesses.value).then(r => {
					this.company = r.data;
					this.addingAccesses.value = 0
				}).finally(() => this.isLoading = false)
			}
		},
		reduceAccess() {
			if (this.reduceAccesses.value < 1) {
				this.reduceAccesses.error = 'Введите больше 0'
			} else {
				this.reduceAccesses.error = '';
				this.isLoading = true;
				api.reduceAccessToCompany(this.company.id, this.reduceAccesses.value).then(r => {
					this.company = r.data;
					this.reduceAccesses.value = 0
				}).finally(() => this.isLoading = false)
			}
		},
		actionWithAccess(isAddAccess) {
			if(isAddAccess) {
				this.addAccesses();
			} else {
				this.reduceAccess()
			}
			this.isAccess = false;
		},
		deleteCompany(id) {
			api.deleteCompany(id)
				.then(r => {
					this.$router.push('/company');
				})
		},
		getTeams(id) {
			this.isLoading = true;

			api.getCompanyById(id).then(r => {
				this.company = r.data
				this.company.teams = this.company.teams.filter(t => t.id != this.deletedTeam)
			})
				.catch(err => {
					if (err.response.status == 404 || err.response.status == 400) this.$router.push('/404')
				})
				.finally(() => this.isLoading = false)

		},
		addTeam(name) {
			this.isLoading = true;
			api.addTeamToCompany(this.company.id, name).then(r => {
				this.getTeams(this.$route.params.id);
				this.isAddingTeamExists = false;
			})
				.catch(err => err.response.status == 409 ? this.isAddingTeamExists = true : '')
				.finally(() => this.isLoading = false)
		},
		getAdminToken() {
			api.getAdminToken(this.company.id).then(r => this.adminTokenLink = 'https://' + window.location.host + '/t/' + r.data.token)
		},
    getLocalLanguage() {
      const currentLocale = this.$i18n.locale;
      //const currentLocale = 'en'
      if (currentLocale === 'en') {
        this.isLocaleEn = true;
      }
    },

	},

  created() {
    this.getLocalLanguage();
  },
	computed: {

		...mapGetters({
			isAnyLoading: 'isAnyLoading',
		}),
		admin() {
			return this.company.representative
		},
		adminFullName() {
			return this.admin.firstName + " " + this.admin.lastName;
		}
	},
	beforeMount() {
		this.getTeams(this.$route.params.id)
	},
	async beforeRouteUpdate(to, from) {
		this.getTeams(to.params.id)
	},


}
</script>

<style lang="scss" scoped>
.block__loader {
  width: 100%;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.company {
	&__panel {
		display: flex;
    justify-content: space-between;
		flex-wrap: wrap;
    margin: 0 auto;
	}

	&__info {
		display: flex;
		justify-content: space-between;
    align-items: flex-start;
		padding: 15px 30px;
		margin: 10px 0;
    width: 100%;
	}

	&__title {
    font-size: 24px;
    font-weight: 500;
    line-height: 25px;
    margin-bottom: 20px;
  }

  &__phone {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #000000;
  }

	&__admin {
		padding: 15px 30px;
    width: 100%;
	}

	&__settings {
    margin-left: 20px;

		& img {
			cursor: pointer;
			transition: all 0.3s linear;

			&:hover {
				transform: rotate(90deg) scale(1.1);
			}
		}

	}
}


.back__button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  color: #070C1F;
  padding: 5px 11px;
  border: none;
  background: #FFFFFF;
  border-radius: 11px;
  transition: 0.2s all linear;

  &:hover {
    transform: scale(98%);
  }

  & img {
    max-height: 20px;
    margin-right: 5px;
  }
}

.margins {
  margin-bottom: 5px;
}

.btn-delete {
	color: #e73535;
	cursor: pointer;

	&:hover {
		font-weight: bold;
		color: red;
	}
}

.admin__contacts {
  color: #000000;
  display: inline-block;
  font-size: 12px;
}

.subtitle {
	color: rgba(7, 12, 31, 0.60);
	font-size: 14px;
  margin-bottom: 14px;
}

.panel__item {
	background-color: white;
	border-radius: 21px;
  margin: 20px 0 0;
}

.item {
	margin: 0 10px 10px;
}


.access-form {
	display: flex;
	justify-content: space-between;
  flex-direction: column;
	input {
		width: 50px;
	}

  &__operations {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}

.error {
	color: red;
	font-size: 12px;
}

@media (min-width: 768px) {
  .access-form {

    &__operations {
      flex-direction: row;
    }
  }

  .panel__item {
    margin: 0;
  }

	.company {
		&__panel {
			margin-top: 20px;
		}

		&__info {
			margin: 0;
      width: 49%;
		}

		&__title {
			font-size: 22px;
		}

    &__admin {
      width: 49%;
    }
	}


	.subtitle {
		font-size: 16px;
	}

  .isCanManage {
    width: 100%;
  }
}


.back__button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  color: #070C1F;
  padding: 5px 11px;
  border: none;
  background: #FFFFFF;
  border-radius: 11px;
  transition: 0.2s all linear;

  &:hover {
    transform: scale(98%);
  }

  & img {
    max-height: 20px;
    margin-right: 5px;
  }
}



@media (min-width: 992px) {
	.company {
		&__admin {
			justify-content: space-between;
		}

    &__info {
      margin-right: 15px;
    }
	}

  .company {
    &__panel {
      flex-wrap: nowrap;
    }

  }


}

@media (min-width: 1200px) {
  .subtitle {
  font-size: 14px;
  }
}

@media (min-width: 1400px) {
	.company {
		&__info {
			padding: 30px;
		}
	}

  //.company__promo {
  //  padding: 30px 35px;
  //
  //  &__title {
  //    font-size: 24px;
  //  }
  //}
}
</style>
