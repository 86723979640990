<template>
  <div id="result" class="d-flex flex-column align-items-center">

    <div v-for="n in [...Array(7).keys()].slice().reverse()" :key="n" class="result__row">

        <div @click="$router.push('levels/' + (n+1))" class="result__back" :style="{width: maxWidth + 'px'}">
          <div  class="result__block" :id="n"
                :style="{'background-color' : colors[n], width: getWidth(result[n]) + 'px'}">{{getPersent(result[n])}}%</div>
        </div>
        <div class="result__text text-nowrap"> <span>{{n+1}}. {{levelNames[n]}}</span></div>

    </div>

  </div>
</template>

<script>
export default {
  name: "LevelTestDisplay",
  data() {
    return {
      colors:['#EC3E34','#E99737','#F6B818','#28B576','#11828D','#1C6CAD','#734398'],
      levelNames:['Выживание','Защита','Рационализм','Альтруизм','Вдохновение','Творчество','Просветление'],
    }
  },
  props:{
    result:{
      type: Array,
      required: true,
    }
  },
  methods:{
    getWidth(result) {
      return result * (this.maxWidth/100);
    },
    getPersent(result) {
      return Math.floor(result);
    }
  },
  computed:{
    maxResult(){
      return Math.max.apply(null, this.result);
    },
    maxWidth(){
      if(window.innerWidth > 765 ) {
        return 220
      } else return 150;
    }
  },
  mounted(){
    console.log(window.innerWidth);
  }
}
</script>

<style lang="scss" scoped>
.result{
  &__text{

    font-weight: 500;
    font-size: 18px;

    line-height: 100%;
  }
  &__row{
    display: flex;
    width: 100%;
    align-items: center;

    margin-top: 7px;
    justify-content: space-between;
  }
  &__back{
    background-color: #EAEDF6;
    border-radius: 78px;
    margin-right: 20px;
    width: 50%;
    transition: all 0.2s linear;
    cursor: pointer;
    &:hover{
      transform: scale(105%);
    }
  }
  &__block{
    text-align: center;
    color:white;
    font-weight: 700;
    font-size: 20px;
    border-radius: 78px;

  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .result{
    &__text{
      font-size: 18px;
    }
    &__row{
      margin-top: 10px;
      justify-content: space-between;
    }
    &__back{
      border-radius: 78px;
      margin-right: 40px;
    }
    &__block{
      font-size: 20px;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .result{
    &__text{
      font-size: 20px;
    }
    &__row{
      margin-top: 10px;

    }
    &__back{
      border-radius: 78px;
      margin-right: 40px;
    }
    &__block{
      font-size: 20px;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
.result__row{
  justify-content: normal;
}
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {  }

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  .result{
    &__text{
      font-size: 24px;
    }
    &__back{
      margin-right: 50px;
    }
    &__block{
      padding: 4px 0;
      font-size: 24px;
      border-radius: 78px;
    }
  }
}


</style>
